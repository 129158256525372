import store from '@/store';
import { loadView } from './_utils';

const beforeEnterGuard = async (to, from, next) => {
    await store.dispatch('settings/parentalControls/checkParentalSet');

    const isParentalSet = store.state.settings.parentalControls.isParentalSet;
    if (to.meta.requiresVPC !== isParentalSet) {
        const name = isParentalSet ? 'parental-controls.vpc-complete' : 'parental-controls.start';
        next({ ...to, name, replace: true });
    } else next();
};

export default [
    {
        path: 'parental-controls/not-eligible',
        name: 'parental-controls.not-eligible',
        component: loadView('setting/parental-controls/not-eligible'),
    },
    {
        path: 'setting/parental-controls/start',
        name: 'parental-controls.start',
        component: loadView('setting/parental-controls/start'),
        meta: { requiresLogin: true, requiresVPC: false },
        beforeEnter: beforeEnterGuard,
    },
    {
        path: 'setting/parental-controls/vpc',
        name: 'parental-controls.vpc',
        component: loadView('setting/parental-controls/vpc'),
        meta: { requireSettingsInfo: true, requiresVPC: false },
        beforeEnter: beforeEnterGuard,
    },
    {
        // only for TW region
        path: 'setting/parental-controls/vpc/sms',
        name: 'parental-controls.vpc-sms',
        component: loadView('verify-phone'),
        props: {
            title: 'parental-controls.vpc.title',
            routeTo: 'parental-controls.vpc-complete',
            cancelTo: 'setting.parental-controls',
        },
        meta: { requireSettingsInfo: true, requiresVPC: false },
        beforeEnter: beforeEnterGuard,
    },
    {
        path: 'setting/parental-controls/vpc/complete',
        name: 'parental-controls.vpc-complete',
        component: loadView('setting/parental-controls/vpc/complete'),
        meta: { requiresLogin: true, requiresVPC: true },
        beforeEnter: beforeEnterGuard,
    },
    {
        path: 'setting/parental-controls/add-child/signup',
        name: 'add-child-signup',
        component: loadView('create/create-account'),
        meta: { requireSettingsInfo: true, requiresVPC: true },
        beforeEnter: beforeEnterGuard,
    },
    {
        path: 'setting/parental-controls/add-child/login',
        name: 'add-child-login',
        component: loadView('login'),
        meta: { requiresLogin: true, requiresVPC: true },
        beforeEnter: beforeEnterGuard,
    },
    {
        path: 'setting/parental-controls/add-child/complete',
        name: 'add-child-complete',
        component: loadView('setting/parental-controls/add-child-complete'),
        meta: { requiresLogin: true, requiresVPC: true },
        beforeEnter: beforeEnterGuard,
    },
];
