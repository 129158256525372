import { loadView } from './_utils';

const selfServiceRoutes = [
    {
        path: 'self-service',
        component: {
            render(c) {
                return c('router-view');
            },
        },
        meta: { coQuery: ['parental_flow_mode'] },
        children: [
            {
                path: '',
                name: 'self-service',
                component: loadView('self-service'),
                meta: { requiresNotLogin: true },
            },
            {
                path: 'reset-password',
                name: 'self-reset-password',
                props: true,
                component: loadView('self-service/reset-password'),
            },
            {
                path: 'confirm-email',
                name: 'self-confirm-email',
                props: true,
                component: loadView('self-service/reset-password/confirm-email'),
            },
            {
                path: 'reset-password/success',
                name: 'self-reset-password-success',
                component: loadView('self-service/reset-password/success'),
            },
            {
                path: 'find-email',
                name: 'self-find-email',
                component: loadView('self-service/find-email'),
            },
            {
                path: 'find-email/completed',
                name: 'self-find-email-completed',
                props: true,
                component: loadView('self-service/find-email/completed'),
            },
            {
                path: 'change-email',
                name: 'self-change-email',
                props: true,
                component: loadView('self-service/change-email'),
            },
            {
                path: 'change-email/completed',
                name: 'self-change-email-completed',
                props: true,
                component: loadView('self-service/change-email/completed'),
            },
            {
                path: 'change-email/failed',
                name: 'self-change-email-failed-cooldown',
                component: loadView('self-service/change-email/failed-cooldown-period'),
            },
            {
                path: 'no-access-to-authenticator',
                name: 'self-no-auth-access',
                component: loadView('self-service/no-access-authenticator'),
            },
            {
                path: 'auth-types',
                name: 'self-auth-types',
                component: loadView('self-service/verify-account/auth-types-2fa'),
            },
            {
                path: 'verify-code',
                name: 'self-verify-2fa',
                component: loadView('self-service/verify-account/verify-code-2fa'),
            },
            {
                path: 'verify-account',
                name: 'self-verify-email',
                props: true,
                component: loadView('self-service/verify-account/verify-email'),
            },
            {
                path: 'verify-sms',
                name: 'self-verify-sms',
                component: loadView('self-service/verify-account/verify-code-sms'),
            },
            {
                path: 'too-many-attempts',
                name: 'self-many-attempts',
                component: loadView('self-service/too-many-attempts'),
            },
            {
                path: 'verify',
                name: 'self-verify-ownership',
                component: loadView('self-service/ownership-form'),
            },
            {
                path: 'verify/escalate',
                name: 'self-verify-ownership-escalate',
                component: loadView('self-service/ownership-form/escalate'),
            },
            {
                path: 'verify/rejected',
                name: 'self-verify-ownership-rejected',
                component: loadView('self-service/ownership-form/rejected'),
            },
        ],
    },
];

export default selfServiceRoutes;
