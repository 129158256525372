<template>
    <div class="message-box dialog-mask trusted-device-dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('trusted-device-modal.edit-title') }}</h4>
                </div>
                <div class="modal-body">
                    <p class="modal-desc">
                        {{ $t('trusted-device-modal.edit-description') }}
                    </p>
                    <div class="input-container">
                        <label for="deviceName">{{ $t('trusted-device-modal.input-label') }}</label>
                        <FormInput
                            v-model="$v.deviceName.$model"
                            data-cy="device-name-modal"
                            :has-error="submitted && $v.deviceName.$error"
                            :override-error-text="$t(inputDeviceNameErrorText, [1, 128])"
                        >
                        </FormInput>
                    </div>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :btn-primary-text="$t('trusted-device-modal.change')"
                        :btn-secondary-text="$t('cancel')"
                        btn-primary-id="edit-device"
                        btn-secondary-id="cancel"
                        :primary-button-click="() => close(true)"
                        :secondary-button-click="() => close(false)"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModalMultiButtons, FormInput } from '@arena/toolkit';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        FormInput,
        ModalMultiButtons,
    },
    mixins: [modalEscMixin],
    props: {
        arguments: {
            type: Array,
        },
    },
    data() {
        return {
            deviceName: '',
            submitted: false,
        };
    },
    validations: {
        deviceName: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(128),
        },
    },
    computed: {
        inputDeviceNameErrorText() {
            if (!this.$v.deviceName.required) {
                return 'error.input.trusted-device-name';
            }
            if (!this.$v.deviceName.maxLength) {
                return 'error.input.length.device-name';
            }
            return 'error.input.not-valid-device-name';
        },
    },
    created() {
        this.deviceName = this.arguments[0];
        this.handleEscCallbackToMixin = () => this.close(false);
    },
    methods: {
        close(rememberDevice) {
            if (rememberDevice) {
                this.$v.$touch();
                this.submitted = true;
                if (!this.$v.$invalid) {
                    this.$close({ rememberDevice: rememberDevice, deviceName: this.deviceName });
                }
            } else {
                this.$close({ rememberDevice: rememberDevice });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.trusted-device-dialog {
    .modal-desc {
        margin-bottom: 10px;
    }
    .input-container {
        padding-top: 16px;
    }
    .modal-footer {
        padding-top: 0px;
    }
}
</style>
