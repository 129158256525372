import router from '@/router';

export default {
    namespaced: true,
    state: {
        serialNumber: '', // uuid for creating authentication session
        operationType: '', // resetPassword, findEmail or changeId for creating authentication session and in to know where to redirect after verify code or automated form ownership
        authTypesData: {
            authTypes: [],
            primaryAuthType: '',
            phoneNo: '',
        },
        gameList: [],
        enteredEmail: '', // entered email on reset password or change email flow. it's used to display it on verify email page
        maplestoryRegionOptions: [
            {
                label: 'region-na',
                value: 'NA',
            },
            {
                label: 'region-eu',
                value: 'EU',
            },
        ],
    },
    getters: {
        getSerialNumber(state) {
            return state.serialNumber;
        },
        getGameList(state) {
            return state.gameList;
        },
        getOperationType(state) {
            return state.operationType;
        },
        getMaplestoryRegionOptions(state) {
            return state.maplestoryRegionOptions;
        },
    },
    actions: {
        async createAuthSession({ state, commit, dispatch }, action = '') {
            try {
                const [captchaToken, captchaVersion] = await dispatch('recaptcha/getToken', action, { root: true });

                const data = await this._vm.$selfServiceAPI.post('/no-auth/session', {
                    captchaToken,
                    captchaVersion,
                    operationType: state.operationType,
                });
                commit('setSerialNumber', data.serialNumber);
            } catch (err) {
                if (err.errorCode === 20157) {
                    // TOO_MANY_REQUEST;
                    router.push({
                        name: 'self-many-attempts',
                    });
                }
                throw err;
            }
        },
        async getAuthTypes(
            { state, commit, dispatch },
            { searchBy, value, captchaAction, productId, gameName, password, maplestoryRegion, isSetupPassword } = {}
        ) {
            await dispatch('createAuthSession', captchaAction);

            const data = await this._vm.$selfServiceAPI.get(`/no-auth/mfa/${searchBy}/auth-type`, {
                params: {
                    serial_number: state.serialNumber,
                    value,
                    ...(productId ? { product_id: productId } : {}),
                    ...(password ? { password } : {}),
                    ...(maplestoryRegion ? { add_param: maplestoryRegion } : {}),
                },
            });

            window.dataLayer.push({
                event: 'accountRecoverySelfService_Step1',
                'AR Flow': state.operationType,
                ...(state.operationType === 'findEmail' ? { 'AR Find Email Type': searchBy } : {}),
                ...(gameName ? { 'AR Find Email Game': gameName } : {}),
            });

            commit('setAuthTypesData', data);
            commit('setEnteredEmail', searchBy === 'email' ? value : '');

            switch (state.authTypesData.primaryAuthType) {
                case 'EMAIL':
                    if (state.operationType === 'findEmail' && state.authTypesData.authTypes.length <= 1) {
                        // findEmail flow doesn't allow to use verify email code, in case that there is no TOTP or SMS then redirect user to the automated form
                        router.push({ name: 'self-verify-ownership' });
                    } else {
                        router.push({ name: 'self-verify-email', params: { isSetupPassword } });
                    }
                    break;
                case 'TOTP':
                    router.push({ name: 'self-verify-2fa' });
                    break;
                case 'SMS':
                    router.push({ name: 'self-verify-sms' });
                    break;
                default:
                    router.push({ name: 'self-verify-ownership' });
            }
        },

        async verifyCode({ rootGetters, state, dispatch }, { code, authType, isSetupPassword }) {
            await this._vm.$selfServiceAPI.put(`/no-auth/mfa/${authType}/verify`, {
                code,
                serialNumber: state.serialNumber,
                deviceId: rootGetters.deviceId,
            });

            window.dataLayer.push({
                event: '2FA Required on Recovery',
                '2FAType': authType === 'totp' ? 'APP' : authType === 'email' ? 'Email' : 'SMS',
                '2FA Recovery Source': state.operationType,
            });

            await dispatch('handleVerifyCodeFormSuccess', { isSetupPassword });
        },

        async handleVerifyCodeFormSuccess({ state }, { isAutomatedForm = false, isSetupPassword = false }) {
            // handling success verify code or got enough score for verify ownership form
            // eslint-disable-next-line no-useless-catch
            try {
                let isLegacy, data;

                if (isAutomatedForm || state.authTypesData.primaryAuthType === 'EMAIL') {
                    // check if current account is a legacy one after verify ownership (automated form) or normal flow with only EMAIL auth type enabled
                    data = await this._vm.$selfServiceAPI.get('/no-auth/op/migrate/check', {
                        params: {
                            serial_number: state.serialNumber,
                        },
                    });

                    isLegacy = data.isLegacy;
                    if (isLegacy) {
                        window.dataLayer.push({
                            event: 'accountRecoverySelfService_LegacyAccountFound',
                            'AR Flow': state.operationType,
                        });
                    }
                }

                switch (state.operationType) {
                    case 'resetPassword':
                        if (isLegacy) {
                            // if is legacy account display confirmation email page for migration
                            router.push({
                                name: 'self-confirm-email',
                                params: {
                                    currentEmail: data.email ? data.email : '',
                                },
                            });
                        } else {
                            // otherwise go reset password and display password fields
                            router.push({
                                name: 'self-reset-password',
                                params: {
                                    showPasswordFields: true,
                                    isSetupPassword,
                                },
                            });
                        }
                        break;

                    case 'findEmail':
                        if (isLegacy) {
                            // if is legacy account go to completed page and display old account
                            router.push({
                                name: 'self-find-email-completed',
                                params: {
                                    foundAccount: data.email ? data.email : data.userId,
                                    isLegacy: isLegacy,
                                },
                            });
                        } else {
                            // otherwise call findEmail api and display the found result
                            const result = await this._vm.$selfServiceAPI.get('/no-auth/op/email', {
                                params: {
                                    serial_number: state.serialNumber,
                                },
                            });
                            const foundAccount = result.id ? result.id : '';
                            const anotherRegion = result.region ? result.region : '';

                            window.dataLayer.push({
                                event: 'accountRecoverySelfService_FindEmail',
                                'AR Find Email Result': foundAccount
                                    ? 'Found'
                                    : anotherRegion
                                    ? 'In Other Region'
                                    : 'Not Found',
                                ...(anotherRegion ? { 'AR Region': anotherRegion } : {}),
                            });

                            router.push({
                                name: 'self-find-email-completed',
                                params: {
                                    foundAccount,
                                    anotherRegion,
                                },
                            });
                        }
                        break;

                    case 'changeId':
                        if (isLegacy) {
                            // if is legacy account go to completed page and display old account
                            router.push({
                                name: 'self-change-email-completed',
                                params: {
                                    accountId: data.email ? data.email : data.userId,
                                    isLegacy: isLegacy,
                                },
                            });
                        } else {
                            // otherwise go to change email and display new email field
                            window.dataLayer.push({
                                event: 'accountRecoverySelfService_EmailChangeReady',
                            });
                            router.push({
                                name: 'self-change-email',
                                params: {
                                    showNewEmailField: true,
                                },
                            });
                        }
                        break;
                }
            } catch (err) {
                throw err;
            }
        },
        async newEmailRequestCode({ state }, newId) {
            // eslint-disable-next-line no-useless-catch
            try {
                await this._vm.$selfServiceAPI.post('/no-auth/op/id/request-code', {
                    newId: newId,
                    serialNumber: state.serialNumber,
                });
            } catch (err) {
                throw err;
            }
        },
        async fetchGameList({ commit, rootGetters }) {
            // eslint-disable-next-line no-useless-catch
            try {
                const data = await this._vm.$coreAPI.get('/game-product/no-auth/v1/region-products', {
                    params: {
                        membership_no: rootGetters.membershipNo,
                    },
                });
                commit('setGameList', data);
            } catch (err) {
                throw err;
            }
        },
    },
    mutations: {
        setSerialNumber(state, value) {
            state.serialNumber = value;
        },
        setAuthTypesData(state, data) {
            state.authTypesData = data;
        },
        setOperationType(state, operationType) {
            state.operationType = operationType;
        },
        setGameList(state, data) {
            state.gameList = data;
        },
        setEnteredEmail(state, data) {
            state.enteredEmail = data;
        },
    },
};
