/* 
	Wrapper for Recaptcha v3 vue library to wait for recaptcha script to load before executing
	https://github.com/AurityLab/vue-recaptcha-v3/issues/3
*/

const isRecaptchaLoaded = (env) => env['$recaptchaLoaded'](),
    executeRecaptchaV3 = (env, action, needToken) =>
        isRecaptchaLoaded(env).then(() =>
            env['$recaptcha'](action)
                .then((res) => (needToken ? Promise.resolve(res) : {}))
                .catch((err) => (needToken ? Promise.reject(err) : {}))
        ),
    sendRecaptchaV3Action = (env, action) => executeRecaptchaV3(env, action, false),
    getRecaptchaV3Token = (env, action) => executeRecaptchaV3(env, action, true);

export default { sendRecaptchaV3Action, getRecaptchaV3Token, isRecaptchaLoaded };
