<note> Temp footer until global nav and footer ready </note>

<template>
    <footer id="launcher-footer" class="footer">
        <div v-if="isLauncherDebug" class="region-selector">
            <span> {{ $t('region') }}</span>
            <div><tooltip message="region-selector-tooltip" tooltip-id="regionSelector"></tooltip></div>
            <formSelect
                v-model="inputRegionCode"
                class="select-box"
                string-selector="regions"
                :options="regions"
            ></formSelect>
            <FormButton
                class="btn-sm btn-gray"
                :label="$t('relaunch')"
                :button-click="relaunch"
                :disabled="currentRegionCode == inputRegionCode"
            ></FormButton>
        </div>
        <div>
            <a v-if="!isJapan" class="nexon-logo-footer" :href="portalUrl" :target="hrefTarget">
                <svgIcon icon-holder-class="logo" icon-id="nexon-logo-full" />
            </a>
            <span v-else class="nexon-logo-footer">
                <svgIcon icon-holder-class="logo" icon-id="nexon-logo-full" />
            </span>
            <div class="copyright">{{ $t('copyright', [currentYear]) }}</div>

            <div
                v-if="languageList && languageList.length > 1"
                ref="select"
                v-click-outside="close"
                class="locale"
                :class="{ 'show-languages': showLanguage }"
            >
                <div
                    class="current-language"
                    @click="toggle()"
                    @keydown.esc.stop.prevent="close"
                    @keydown.space.stop.prevent="toggle"
                    @keydown.enter.stop.prevent="toggle"
                >
                    <svgIcon icon-holder-class="icon-locale" icon-id="icon-globe" />
                    <span>{{ $t(`languages.${$i18n.locale}`) }}</span>
                </div>

                <ul class="languages list-unstyled" :class="{ 'show-languages': showLanguage }">
                    <li v-for="locale in languageList" :key="locale">
                        <a @click="setLang(locale)">
                            {{ $t(`languages.${locale.split('-')[0]}`) }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
import svgIcon from '@arena/toolkit/src/components/common/icon-svg';
import clickOutside from '@/utils/click-outside';
import { FormSelect, FormButton } from '@arena/toolkit';
import tooltip from '@/components/global/tooltip';
import launcherAPI from '@/modules/launcher';

export default {
    name: 'GlobalFooter',
    components: {
        svgIcon,
        FormSelect,
        FormButton,
        tooltip,
    },
    directives: {
        clickOutside,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            showLanguage: false,
            regions: [
                { label: 'global', value: 1 },
                { label: 'th', value: 3 },
                { label: 'tw', value: 4 },
                { label: 'sea', value: 5 },
            ],
            inputRegionCode: 1,
            languagesLauncher: [
                'es-419',
                'en-US',
                'pt-BR',
                'de-DE',
                'fr-FR',
                'it-IT',
                'nl-NL',
                'pl-PL',
                'tr-TR',
                'ru-RU',
                'th-TH',
                'zh-TW',
            ],
        };
    },
    computed: {
        isLauncherDebug() {
            return this.$store.state.isLauncher && window.parent.application.debug;
        },
        portalUrl() {
            return this.$store.state.configurations.portalUrl;
        },
        hrefTarget() {
            return this.$store.state.channel === 'web' ? '_self' : '_blank';
        },
        logoutUrl() {
            return `/account/${this.$i18n.locale}/logout`;
        },
        languageList() {
            if (this.$store.state.isApplication) return this.languagesLauncher; // for launcher and steam connector
            return [...this.languagesLauncher, 'ja-JP']; // for toy, console games
        },
        currentRegionCode() {
            return (this.regions.find((x) => x.label === environment.region) || { value: 1 }).value;
        },
        isJapan() {
            return environment.region === 'japan';
        },
    },
    mounted() {
        this.inputRegionCode = this.currentRegionCode;
    },
    methods: {
        close() {
            this.showLanguage = false;
        },
        toggle() {
            this.showLanguage = !this.showLanguage;
        },
        select() {
            this.toggle();
        },
        setLang(locale) {
            const langCode = locale.split('-')[0];
            this.$cookie.set('i18next', this.$i18n.locale);
            this.showLanguage = false;

            if (this.$store.state.isApplication) {
                // The following function is injected into window by the launcher
                launcherAPI.launcherLanguageChange(locale);
            }
            this.$router.push({ params: { locale: langCode }, query: this.$route.query });
        },
        relaunch() {
            window.parent.coreappIpc.send('changeRegion', String(this.inputRegionCode));
        },
    },
};
</script>
<style lang="less">
#launcher-footer {
    .region-selector {
        color: #ffffff;
        margin-bottom: 12px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.59);
        display: table;
        margin: 10px auto 20px;
        > * {
            display: table-cell;
            vertical-align: middle;
        }
        > span {
            opacity: 0.5;
        }
        .select-box {
            width: 193px;
            padding: 0 10px 0 18px;
            .form-group {
                margin: 0;
                > label {
                    display: none;
                }
            }
            .form-control {
                padding: 4px 8px;
                height: 30px;
                color: rgba(255, 255, 255, 0.5);
                text-align: left;
                font-size: 12px;
                line-height: 20px;
                background-color: black;
                border: 1px solid rgba(255, 255, 255, 0.1);
                .caret {
                    right: 10px;
                    top: calc(50% - 2px);
                    border-top: 5px dashed;
                    border-right: 5px solid transparent;
                    border-left: 5px solid transparent;
                }
                .btn-content {
                    display: inline;
                    vertical-align: middle;
                }
                &:hover {
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    .caret {
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
            .open {
                .caret {
                    border-top: initial;
                    border-bottom: 5px dashed;
                }
            }
            ul.dropdown-menu {
                top: initial;
                bottom: 30px;
                background-color: black;
                border: 1px solid rgba(255, 255, 255, 0.005);
                > li {
                    background-color: black;
                    a {
                        color: rgba(255, 255, 255, 0.5);
                        &.highlight {
                            color: white;
                            background-color: rgba(255, 255, 255, 0.05);
                        }
                    }
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.05);
                        a {
                            background-color: rgba(255, 255, 255, 0.05);
                        }
                    }
                }
                &:hover {
                    border: 1px solid rgba(255, 255, 255, 0.3);
                }
            }
        }

        .tooltip-anchor {
            position: relative;
            .tooltip-body {
                position: absolute;
                left: 0;
                bottom: 30px;
            }
        }

        .btn-gray {
            color: #ffffff;
            background-color: #393c43;
            border: solid 1px rgba(0, 0, 0, 0.2);
            &:hover {
                color: #ffffff;
                background-color: #4b4e57;
                border: solid 1px rgba(0, 0, 0, 0.2);
            }
            &:active {
                color: rgba(255, 255, 255, 0.6);
                background-color: #2c2e33;
            }
        }
        .btn-sm {
            height: 30px;
            min-width: 120px;
            width: auto;
            padding: 0 10px;
            margin: 0;
            font-weight: normal;
            line-height: 20px;
        }
    }
}
</style>
<style lang="less" scoped>
.footer {
    background-color: #262626;
    padding: 16px 20px 0;
    font-size: 12px;
    text-align: center;
    position: absolute; //temp positioning
    bottom: 0;
    width: 100%;

    .copyright {
        vertical-align: top;
        display: inline-block;
        margin: 2px 5px 8px;
        line-height: 1.5em;
    }
    .locale {
        display: inline-block;
        position: relative;
        margin: 2px 5px 10px;
        &.show-languages,
        .show-languages {
            display: inline-block;
        }
        .icon-locale {
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
        .current-language {
            cursor: pointer;
            &:hover {
                color: #00decb;
            }
        }
        span {
            margin-top: 2px;
            vertical-align: top;
            display: inline-block;
        }
    }
    .languages {
        display: none;
        position: absolute;
        width: 120px;
        text-align: left;
        bottom: -8px;
        left: 25px;

        box-shadow: 3px 3px 15px 3px #000000;

        background-color: #161616;

        li {
            position: relative;
            line-height: 2em;
            &:hover {
                background-color: #00decb;
            }
            a {
                display: inline-block;
                padding: 2px 15px;
                cursor: pointer;
            }
        }
    }
}
.logo {
    width: 100px;
    height: 25px;
    margin: 0 5px 10px;
}
.nexon-logo-footer {
    vertical-align: top;
    display: inline-block;
}
@media (max-width: 480px) {
    .footer {
        padding: 20px 10px 10px;
        .logo,
        .locale,
        .copyright {
            margin-bottom: 10px;
        }
    }
}
</style>
