export default {
    namespaced: true,
    state: {
        isParent: true,
        isParentalSet: false,
        parent: {},
        children: [],
    },
    actions: {
        async patchFamilyMember({ commit }) {
            return await this._vm.$accountSettingAPI
                .get('/parental/family-member')
                .then((data) => commit('setFamilyData', data))
                .catch(({ errorCode }) => {
                    if (errorCode !== 20002) {
                        commit('setIsParentalSet', false);
                        // throw { errorCode };
                    }
                });
        },
        async checkParentalSet({ commit }) {
            commit('setIsParentalSet', isParentalSet);
            const isParentalSet = await this._vm.$accountSettingAPI
                .get('/parental/vpc/completion/check')
                .then(() => true)
                .catch(() => false);
            commit('setIsParentalSet', isParentalSet);
        },
        async checkParentalSetRequirements() {
            const result = await this._vm.$accountSettingAPI
                .get('/parental/vpc/requirement/check')
                .then(() => true)
                .catch(() => false);
            return result;
        },
        async completeSMSVerification() {
            return await this._vm.$accountSettingAPI.put('/parental/vpc/sms/complete').catch(({ errorCode }) => {
                // already set
                if (errorCode === 20229) return true;
                throw { errorCode };
            });
        },
    },
    mutations: {
        setFamilyData(state, payload) {
            state.isParent = payload.isParent;
            state.parent = payload.parent;
            state.children = payload.children;
            state.isParentalSet = true;
        },
        setIsParentalSet(state, payload) {
            state.isParentalSet = payload;
        },
    },
};
