import axios from 'axios';
import { paramsFormatter, accountErrorHandling } from './intercepters';

const regionalAPI = axios.create({
    baseURL: `/api/regional-auth/v1.0`,
    withCredentials: true,
});

regionalAPI.defaults.headers.common['x-arena-fe-version'] = `account-${process.env.VUE_APP_VERSION}`;
if (window.application && window.application.sessionId) {
    regionalAPI.defaults.headers.common['x-nxl-session-id'] = window.application.sessionId;
}

regionalAPI.interceptors.request.use(paramsFormatter);
regionalAPI.interceptors.response.use((response) => response.data, accountErrorHandling);

export default regionalAPI;
