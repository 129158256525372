import { loadView } from './_utils';

const globalRoutes = [
    {
        path: 'global/signup',
        name: 'global-simple-signup',
        component: loadView('global/simple-signup'),
        props: true,
    },
    {
        path: 'global/login',
        name: 'global-simple-login',
        component: loadView('global/simple-login'),
        meta: { requiresNotLogin: true },
        props: true,
    },
    {
        path: 'global/callback/social/:socialProvider?',
        name: 'global-simple-login-callback-social',
        component: loadView('global/simple-login-callback-social'),
        props: true,
    },
    {
        path: 'global/authorize',
        name: 'global-authorize',
        component: loadView('global/global-authorize'),
    },
    {
        path: 'global/reset-password',
        name: 'global-reset-simple-password',
        component: loadView('global/reset-simple-password'),
        props: true,
    },
    {
        path: 'global/reset-password/smode',
        name: 'global-reset-simple-password-smode',
        component: loadView('global/reset-simple-password-smode'),
        props: true,
    },
    {
        path: 'global/reset-password/nmode',
        name: 'global-reset-simple-password-nmode',
        component: loadView('global/reset-simple-password-nmode'),
        props: true,
    },
    {
        path: 'global/reset-password/success',
        name: 'global-simple-reset-success',
        component: loadView('global/simple-reset-success'),
        props: true,
    },
    {
        path: 'global/verify/code',
        name: 'global-verify-simple-signup-code',
        component: loadView('global/verify-simple-signup-code'),
        props: true,
    },
    {
        path: 'global/verify/success',
        name: 'simple-success',
        component: loadView('global/link-success'),
        props: true,
    },
    {
        path: 'global/privacy-policy',
        name: 'global-privacy-policy',
        component: loadView('global/privacy-policy'),
    },
    {
        path: 'global/terms-of-use',
        name: 'global-terms-of-use',
        component: loadView('global/terms-of-use'),
    },
    {
        path: 'global/error',
        name: 'global-error',
        component: loadView('error'),
        props: true,
    },
    {
        path: 'global/activate',
        name: 'global-activate',
        component: loadView('global/activate'),
        props: true,
    },
    {
        path: 'global/:platform',
        name: 'global',
        component: loadView('global/frame'),
        meta: { coQuery: ['ticket', 'clientId'] },
        children: [
            {
                path: 'login',
                name: 'global-external-login',
                component: loadView('global/external-login'),
                props: true,
            },
            {
                path: 'verify-linking/auth-types',
                name: 'global-verify-linking-auth-types',
                component: loadView('global/auth-types'),
                props: true,
            },
            {
                path: 'verify-linking/code',
                name: 'global-verify-linking-code',
                component: loadView('global/verify-linking-code'),
                props: true,
            },
            {
                path: 'verify-linking/sms',
                name: 'global-verify-linking-sms',
                component: loadView('global/verify-linking-sms'),
                props: true,
            },
            {
                path: 'verify-linking/2fa',
                name: 'global-verify-linking-2fa',
                component: loadView('global/verify-linking-2fa'),
                props: true,
            },
            {
                path: 'login/callback/social/:socialProvider?',
                name: 'global-login-callback-social',
                component: loadView('global/login-callback-social'),
                props: true,
            },
            {
                path: 'social-link/:case',
                name: 'global-social-link',
                component: loadView('global/social-link'),
                props: true,
            },
            {
                path: 'link-success',
                name: 'link-success',
                component: loadView('global/link-success'),
                props: true,
            },
            {
                path: 'link-failed',
                name: 'link-failed',
                component: loadView('global/link-failed'),
                props: true,
            },
            {
                path: 'instruction',
                name: 'instruction',
                component: loadView('global/instruction'),
            },
        ],
    },
];

export default globalRoutes;
