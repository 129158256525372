import axios from 'axios';

const paymentAPI = axios.create({
    baseURL: `https://${process.env.VUE_APP_PAYMENT_HOST}/api/payment/v1`,
    // baseURL: '/api/payment/v1',
    withCredentials: true,
});

paymentAPI.defaults.headers.common['x-arena-fe-version'] = `account-${process.env.VUE_APP_VERSION}`;
if (window.application && window.application.sessionId) {
    paymentAPI.defaults.headers.common['x-nxl-session-id'] = window.application.sessionId;
}

export default paymentAPI;
