import launcherAPI from '@/modules/launcher';
import router from '@/router';

export default {
    namespaced: true,
    state: {
        autologin: /(NexonLauncher)/gi.test(navigator.userAgent) ? window.parent.rootStore?.session.autoLogin : false,
        data: null,
        clientId: '7853644408',
        loginSessionInfo: {},
    },
    actions: {
        async login({ state, rootState, rootGetters, dispatch }) {
            const [captchaToken, captchaVersion] = await dispatch('recaptcha/getToken', 'launcher_login', {
                root: true,
            });
            const data = await this._vm.$regionalAPI
                .post('/no-auth/launcher/email/login', {
                    id: rootState.login.loginId,
                    password: rootState.login.password,
                    deviceId: rootGetters.deviceId,
                    autoLogin: state.autologin,
                    captchaToken,
                    captchaVersion,
                    localTime: Date.now(),
                    timeOffset: new Date().getTimezoneOffset(),
                })
                .catch((err) => dispatch('login/loginErrorHandle', err, { root: true }));

            await dispatch('login/afterLoginCommon', data, { root: true });
        },

        async loginTpa({ commit, state, rootGetters, dispatch }) {
            const data = await this._vm.$accountAPI
                .post('/no-auth/login/tpa/launcher', {
                    clientId: state.clientId,
                    deviceId: rootGetters.deviceId,
                    localTime: Date.now(),
                    timeOffset: new Date().getTimezoneOffset(),
                })
                .catch((err) => dispatch('login/loginErrorHandle', err, { root: true }));

            commit('setAutologin', false); // launcher tpa login doesn't support autologin

            await dispatch('login/afterLoginCommon', data, { root: true });
        },

        async silentLogin({ state, rootGetters, dispatch }, isTpa) {
            if (isTpa) return await dispatch('loginTpa');
            const data = await this._vm.$accountAPI.post('/no-auth/login/launcher/silent', {
                autoLogin: true, // This is hardcoded as per request from Launcher team
                clientId: state.clientId,
                deviceId: rootGetters.deviceId,
                localTime: Date.now(),
                timeOffset: new Date().getTimezoneOffset(),
            });
            await dispatch('login/afterLoginCommon', data, { root: true });
        },

        // final launcher login
        async afterLogin({ rootState, state }) {
            if (!state.data) return false;

            if (rootState.signupParams && router.currentRoute.name !== 'create-account-complete') {
                router.replace({ name: 'create-account-complete' });
                return;
            }

            const launcherLogin = launcherAPI.launcherLogin({
                ...state.data,
                autoLogin: state.autologin,
            });
            if (launcherLogin) {
                await new Promise((r) => setTimeout(r, 5000)); // wait for login loading animation while launcher is handling it
            }
            return launcherLogin;
        },
        launcherLoginCancel() {
            window.parent && window.parent.launcherLoginCancel
                ? window.parent.launcherLoginCancel()
                : window.launcherLoginCancel
                ? window.launcherLoginCancel()
                : null;
        },
    },
    mutations: {
        setAutologin(state, value) {
            state.autologin = value;
        },
        setData(state, value) {
            state.data = value;
        },
    },
};
