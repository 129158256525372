<template>
    <i18n path="recaptcha.attribution" tag="div" class="recaptcha-attribution">
        <template v-slot:privacyLink>
            <a
                data-cy="link-recaptcha-privacy"
                :href="privacyLink"
                target="_blank"
                rel="external"
                @click="privacyClick"
                >{{ $t('recaptcha.privacy') }}</a
            >
        </template>
        <template v-slot:tosLink>
            <a data-cy="link-recaptcha-terms" :href="tosLink" target="_blank" rel="external" @click="tosClick">{{
                $t('recaptcha.tos')
            }}</a>
        </template>
    </i18n>
</template>

<script>
export default {
    name: 'RecaptchaAttribution',
    computed: {
        languageParam: function() {
            return this.language === 'zh' ? 'zh-TW' : this.language;
        },
        tosLink() {
            return `https://policies.google.com/terms?hl=${this.languageParam || 'en'}`;
        },
        privacyLink() {
            return `https://policies.google.com/privacy?hl=${this.languageParam || 'en'}`;
        },
        language() {
            return this.$i18n.locale;
        },
    },
    methods: {
        privacyClick() {
            this.$emit('privacy-click');
        },
        tosClick() {
            this.$emit('tos-click');
        },
    },
};
</script>

<style lang="less" scoped>
.recaptcha-attribution {
    line-height: 1.42;
    margin: 20px 0;
    a {
        color: inherit;
        text-decoration: underline;
    }
}
</style>
