import axios from 'axios';
import { paramsFormatter, accountErrorHandling } from './intercepters';

export const countryApi = axios.create({
    // baseURL: 'https://test-api.nexon.io',
    baseURL: '/api/country-membership/v1',
    withCredentials: true,
    timeout: 10000,
});

countryApi.defaults.headers.common['x-arena-fe-version'] = `account-${process.env.VUE_APP_VERSION}`;
if (window.application && window.application.sessionId) {
    countryApi.defaults.headers.common['x-nxl-session-id'] = window.application.sessionId;
}

export const coreApi = axios.create({
    baseURL: '/api',
    withCredentials: true,
});

coreApi.defaults.headers.common['x-arena-fe-version'] = `account-${process.env.VUE_APP_VERSION}`;
if (window.application && window.application.sessionId) {
    coreApi.defaults.headers.common['x-nxl-session-id'] = window.application.sessionId;
}

coreApi.interceptors.request.use(paramsFormatter);
coreApi.interceptors.response.use((response) => response.data, accountErrorHandling);
