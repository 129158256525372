import axios from 'axios';
import { paramsFormatter, selfServiceErrorHandling } from './intercepters';

const selfServiceAPI = axios.create({
    baseURL: `/api/self-service/v1.0`,
    withCredentials: true,
});

selfServiceAPI.defaults.headers.common['x-arena-fe-version'] = `account-${process.env.VUE_APP_VERSION}`;
if (window.application && window.application.sessionId) {
    selfServiceAPI.defaults.headers.common['x-nxl-session-id'] = window.application.sessionId;
}

selfServiceAPI.interceptors.request.use(paramsFormatter);
selfServiceAPI.interceptors.response.use((response) => response.data, selfServiceErrorHandling);

export default selfServiceAPI;
