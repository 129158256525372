import router from '@/router';
import launcherAPI from '@/modules/launcher';

export default {
    namespaced: true,
    state: {},
    actions: {
        async login({ rootState, dispatch }) {
            // ial login flow should be redirected by return_url
            if (router.currentRoute.params.platform.toUpperCase() === 'IAL') {
                dispatch('defaultRedirectHandler', null, {
                    root: true,
                });
                return;
            }

            const { accountEmail, nexonTag } = await this._vm.$globalAccountAPI.login();

            dataLayer.push({
                event: 'loginSuccess',
                'Account Type': rootState.tpaClicked,
            });
            router.push({
                name: 'link-success',
                params: {
                    email: accountEmail,
                    nexonTag,
                },
            });
        },
        async verifyWithCode({ dispatch }, { email, code, codeType, social = false }) {
            await this._vm.$globalAccountAPI
                .verifyWithCode({
                    email,
                    code,
                    codeType,
                    social,
                })
                .catch(({ errorCode }) => {
                    // BLOCKED_USER_PORTAL_BAN
                    if (errorCode === 20100 && launcherAPI.isSteamConnector()) {
                        this._vm.$dialog.error(errorCode).then(() => {
                            dispatch('login/launcher/launcherLoginCancel', null, { root: true });
                        });
                    }
                    throw { errorCode };
                });

            if (launcherAPI.isAppMode()) {
                launcherAPI.steamConnector.login(email);
            } else {
                await dispatch('login');
            }
        },
    },
    mutations: {},
};
