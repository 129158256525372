export default {
    namespaced: true,
    state: {
        newsletterList: [],
    },
    actions: {
        async fetchNewsletterList({ rootGetters, dispatch, commit }) {
            await dispatch('country/checkRegionInfo', '', { root: true });
            const { data } = await this._vm.$cdnAPI.getGameList(rootGetters['country/cmsRegionCode']),
                newsletterList = newsletterLists[environment.region],
                length = newsletterList.length,
                processedList = [];

            for (let i = 0; i < length; i++) {
                const newsletter = newsletterList[i],
                    id = getNewsletterId(newsletter),
                    processedNewsletter = {
                        id,
                        isGeneralNewsletter: false,
                    };
                if (isGeneralNewsletter(id)) {
                    processedNewsletter.isGeneralNewsletter = true;
                    processedNewsletter.name = isMobileGamesNewsletter(id) ? 'mobile-games' : 'news-special-offers';
                } else {
                    const match = data.find((game) => game.productID === newsletter.prodId);
                    if (match) processedNewsletter.name = match.name;
                }
                processedList.push(processedNewsletter);
            }
            commit('SET_NEWSLETTER_LIST', processedList);
        },
    },
    mutations: {
        SET_NEWSLETTER_LIST(state, list) {
            state.newsletterList = list;
        },
    },
};

const getNewsletterId = (newsletter) => newsletter[`${process.env.VUE_APP_ENV === 'dev' ? 'dev' : 'prod'}Id`];

const isMobileGamesNewsletter = (id) => id === getNewsletterId(mobileGames);

const isNewsOffersNewsletter = (id) => id === getNewsletterId(newsOffers);

const isGeneralNewsletter = (id) => isMobileGamesNewsletter(id) || isNewsOffersNewsletter(id);

const maplestory = {
    prodId: '10100',
    devId: '10100',
};

const mabinogi = {
    prodId: '10200',
    devId: '10200',
};

const vindictus = {
    prodId: '10300',
    devId: '10300',
};

const kartRiderDrift = {
    prodId: '50203',
    devId: '50210',
};

const v4 = {
    prodId: '59739',
    devId: '59739',
};

const mobileGames = {
    prodId: '59750',
    devId: '31014',
};

const newsOffers = {
    prodId: '59749',
    devId: '31015',
};

const moonlightBlade = {
    prodId: '50202',
    devId: '50202',
};

const mabinogiHeroes = {
    prodId: '50220',
    devId: '50220',
};

const newsletterLists = {
    global: [maplestory, mabinogi, vindictus, kartRiderDrift, v4, mobileGames, newsOffers],
    tw: [moonlightBlade, mabinogiHeroes, v4, kartRiderDrift, mobileGames, newsOffers],
    th: [kartRiderDrift, v4, mobileGames, newsOffers],
    sea: [kartRiderDrift, v4, mobileGames, newsOffers],
};
