/*
 * Site keys for v3 and invisible v2 recaptchas
 * (checkbox v2 isolated in /src/component/common/recaptcha.vue)
 */

const siteKeys = {
    dev: {
        v2Visible: '6LcGFe8aAAAAAACzx1lg1X39Z5jom95bAxB4G9AO',
        v2: '6LdTVe8aAAAAANr9SBMTLCmsS5OQrBNcPpILYLjD',
        v3: '6Lenru0aAAAAAO9cFGktKwWKvWR7dh4D-hp5MrWs',
    },
    testPre: {
        v2Visible: '6LcGFe8aAAAAAACzx1lg1X39Z5jom95bAxB4G9AO',
        v2: '6LdTVe8aAAAAANr9SBMTLCmsS5OQrBNcPpILYLjD',
        v3: '6Lenru0aAAAAAO9cFGktKwWKvWR7dh4D-hp5MrWs',
    },
    prod: {
        v2Visible: '6Lcy7wgUAAAAAHIITOyjnkhfHab1WVC1-xydibR0',
        v2: '6Lekv6IUAAAAAAMw8tbjegu4WTcHtFc6DDH66Gwn',
        v3: '6Le5h6gUAAAAAGhwvFIRD3fR-cKshC1bJ5vnz90k',
    },
};

export default siteKeys[process.env.VUE_APP_ENV] || siteKeys['prod'];
