<template>
    <div ref="sms-opt-in-login-create" tabindex="0" class="message-box dialog-mask sms-opt-in-login-create">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{ $t('sms-opt-in.login-create-modal.title', { gameName: gameName }) }}
                    </h4>
                </div>
                <div class="modal-body">
                    <p>{{ $t('sms-opt-in.login-create-modal.description') }}</p>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        data-cy="buttons"
                        btn-primary-id="change"
                        :btn-primary-text="$t('settings.yes')"
                        :btn-secondary-text="$t('settings.no')"
                        :primary-button-click="() => $close(true)"
                        :secondary-button-click="() => $close(false)"
                    ></ModalMultiButtons>

                    <p class="phone-number-usa-canada">{{ $t('sms-opt-in.login-create-modal.usa-canada') }}</p>
                    <p class="not-minor">{{ $t('sms-opt-in.login-create-modal.not-minor') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModalMultiButtons } from '@arena/toolkit';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'ModalLoginCreateVerify',
    components: {
        ModalMultiButtons,
    },
    data() {
        return {
            gameName: '',
        };
    },
    computed: {
        ...mapState('newsletter', ['newsletterList']),
        productId() {
            return this.$route.query.prod_id;
        },
    },
    async created() {
        await this.fetchNewsletterList();
        const match = this.newsletterList.find((newsletter) => newsletter.id === this.productId);
        if (match) this.gameName = match.name;
    },
    mounted() {
        if (this.$refs['sms-opt-in-login-create']) this.$refs['sms-opt-in-login-create'].focus();
    },
    methods: {
        ...mapActions('newsletter', ['fetchNewsletterList']),
    },
};
</script>

<style lang="less" scoped>
.sms-opt-in-login-create {
    overflow: auto;
    .modal-dialog {
        margin: auto 0 !important;
    }
    .modal-footer {
        .phone-number-usa-canada {
            padding-top: 15px;
            text-align: left;
            margin-bottom: 0;
        }
        .not-minor {
            text-align: left;
        }
    }
}
</style>
