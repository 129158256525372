import axios from 'axios';
import i18n from 'vue-i18n';
import { getBlackbox } from '@arena/toolkit/src/plugins/iovation';
import router from '@/router';
import launcherAPI from '@/modules/launcher';
import { accountErrorHandling, paramsFormatter } from './intercepters';
import store from '@/store';

async function waitForBlackbox(retriesLeft = 10, interval = 300) {
    const { blackbox, finished } = await getBlackbox({ version: '5.2.2' });
    if (finished) {
        return blackbox;
    } else if (retriesLeft) {
        await new Promise((r) => setTimeout(r, interval));
        return waitForBlackbox(retriesLeft - 1, interval);
    } else return blackbox;
}

const globalAccountAPI = axios.create({
    baseURL: '/api/account/v1',
    withCredentials: true,
});

globalAccountAPI.defaults.headers.common['x-arena-fe-version'] = `account-${process.env.VUE_APP_VERSION}`;
if (window.application && window.application.sessionId) {
    globalAccountAPI.defaults.headers.common['x-nxl-session-id'] = window.application.sessionId;
}

globalAccountAPI.interceptors.request.use(paramsFormatter);
globalAccountAPI.interceptors.response.use((response) => response.data, accountErrorHandling);

const isRegionalDomain = () => environment.region !== 'one';
const getPlatform = () => router.currentRoute.params.platform;
const isConnectorFlow = () => launcherAPI.isSteamConnector();
const isIALFlow = () => router.currentRoute.params.platform.toUpperCase() === 'IAL';

const apiPathHelper = (url) =>
    isIALFlow() ? `/no-auth/ial${url}` : `/no-auth/global/${router.currentRoute.params.platform}${url}`;

const apiWrapper = {
    post: (url, ...args) => globalAccountAPI.post(apiPathHelper(url), ...args),
    get: (url, ...args) => globalAccountAPI.get(apiPathHelper(url), ...args),
};

const getLongLocale = () => {
    const locale = i18n.locale || 'en';
    if (locale === 'es') return 'es_419';
    if (locale === 'en') return 'en_US';
    if (locale === 'pt') return 'pt_BR';
    if (locale === 'zh') return 'zh_TW';
    return `${locale}_${locale.toUpperCase()}`;
};

globalAccountAPI.interceptors.request.use(
    (config) => {
        // to fill a membershipNo post request data up automatically, if it is called from regional host website rather then global (one.nexon.com)
        if (isRegionalDomain() && config.method === 'post' && config.data) {
            switch (environment.region) {
                case 'global':
                    config.data.membershipNo = 1;
                    break;
                case 'th':
                    config.data.membershipNo = 3;
                    break;
                case 'tw':
                    config.data.membershipNo = 4;
                    break;
                case 'sea':
                    config.data.membershipNo = 5;
                    break;
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);

const initPlatformSession = ({ sessionTicket, clientId }) => {
    // Allow calling test API instead with a query param
    const isTestMode = router.currentRoute.query.test || document.cookie.includes('cookieCy=true');
    const sessionTicketvalue = isTestMode ? { platformSessionTicket: sessionTicket } : { sessionTicket };
    const ttlSubmitValue = isTestMode && router.currentRoute.query.ttl ? { ttl: router.currentRoute.query.ttl } : {};
    const subEndpoint = isTestMode ? 'login/test-pf-session-cookie' : 'login/pf-session-cookie';
    return globalAccountAPI.post(`/no-auth/global/${getPlatform()}/${subEndpoint}`, {
        clientId,
        ...sessionTicketvalue,
        ...ttlSubmitValue,
    });
};

const login = () => {
    const clientId = router.currentRoute.query.clientId || router.currentRoute.params.clientId;
    return apiWrapper
        .post(`/login/in-game`, isIALFlow() ? { deviceId: store.getters.deviceId } : { clientId })
        .then((data) => {
            if (
                data.hashedGlobalUserNo &&
                ['1888', '1889', '1890'].includes(clientId) &&
                router.currentRoute.params.platform
            ) {
                dataLayer.push({
                    event: 'KRD Global Account Link',
                    globalUserId: data.hashedGlobalUserNo,
                    channel: router.currentRoute.params.platform,
                });
            }
            return data;
        });
};

// CTRL+F endpoints:
//  /signup/email/validate
//  /signup/in-game/email/validate
const checkEmail = ({ email, newsletter, clientId }) => {
    const endpointSubPath = isConnectorFlow() ? 'signup/email/validate' : 'signup/in-game/email/validate';
    return apiWrapper.post(`/${endpointSubPath}`, {
        newsletter,
        clientId,
        email,
        language: getLongLocale(),
    });
};

const checkSocialEmail = ({ email, newsletter }) => {
    return apiWrapper.post(`/signup/social/email/validate`, {
        newsletter,
        email,
        language: getLongLocale(),
    });
};

const steamSettings = window.settings || window.parent.settings;

// CTRL+F endpoints:
//  /signup/social/in-game/email/code
//  /signup/social/email/code
//  /signup/in-game/email/code
//  /signup/email/code
const verifyWithCode = async ({ social = false, email, code, codeType }) => {
    const socialSubPath = social ? '/social' : '';
    const inGameSubPath = isConnectorFlow() ? '' : '/in-game';
    const clientId = router.currentRoute.query.clientId || router.currentRoute.params.clientId;
    const data = {
        email,
        code,
        codeType,
        ...(clientId ? { clientId } : {}),
        deviceId: store.getters.deviceId,
        ...(store.state.partnerKey ? { partnerKey: store.state.partnerKey } : {}),
    };
    data.blackBox = await waitForBlackbox();
    return apiWrapper.post(`/signup${socialSubPath}${inGameSubPath}/email/code`, data);
};

const mailVerificationCode = ({ email }) =>
    apiWrapper.post(`/email/request-code`, {
        email,
        language: getLongLocale(),
    });

/* Initialize the social sign-in process, used for both login and
    account creation. The current implementation creates a session
    cookie that is referenced in further steps.
    https://confluence.nexon.com/display/APISERVER/Global+Account+Web+Back-End+APIs#GlobalAccountWebBack-EndAPIs-POST-/account-webapi/:extPf/login/social-session-cookie
*/
const initSocialSession = async ({ socialType, code, state }) => {
    const data = { socialType, code, state };
    if (isConnectorFlow()) {
        data.blackBox = await waitForBlackbox();
    }
    return apiWrapper.post(`/login/social-session-cookie`, data);
};

// Social signup
// https://confluence.nexon.com/display/APISERVER/Global+Account+Web+Back-End+APIs#GlobalAccountWebBack-EndAPIs-POST-/account-webapi/:extPf/signup/social
// Social signup with specific email
// https://confluence.nexon.com/display/APISERVER/Global+Account+Web+Back-End+APIs#GlobalAccountWebBack-EndAPIs-POST-/account-webapi/:extPf/signup/social/email
// CTRL+F endpoints:
//  /signup/in-game/social/email
//  /signup/social/email
//  /signup/in-game/social
//  /signup/social
const createSocialAccount = async ({ email, newsletter, clientId } = {}) => {
    const inGameSubPath = isConnectorFlow() ? '' : '/in-game';
    const emailSubPath = email !== undefined ? '/email' : '';
    const data = {
        clientId,
        language: getLongLocale(),
        ...(store.state.partnerKey ? { partnerKey: store.state.partnerKey } : {}),
    };

    if (email !== undefined) data.email = email;
    if (newsletter !== undefined) data.newsletter = newsletter;
    if (isConnectorFlow()) {
        data.blackBox = await waitForBlackbox();
        data.deviceId = steamSettings.device_id;
    }
    return apiWrapper.post(`/signup${inGameSubPath}/social${emailSubPath}`, data);
};

const delinkMinorConsole = async () => {
    return globalAccountAPI.delete('/no-auth/ial/delink/minor-console');
};

export default {
    ...globalAccountAPI,
    login,
    initPlatformSession,
    checkEmail,
    checkSocialEmail,
    mailVerificationCode,
    verifyWithCode,
    initSocialSession,
    createSocialAccount,
    delinkMinorConsole,
};
