/**
 * Whitelist safe domains for redirection
 */
export default [
    'nexon.com',
    'nexon.net',
    'zendesk.com',
    'nexon.io',
    'nexon.zendesk.com',
    'id.twitch.tv',
    'discord.com',
    'accounts.google.com',
    'facebook.com',
    'appleid.apple.com',
    'twitter.com',
];
