import recaptchaV3Wrapper from '@/plugins/recaptcha-v3-wrapper';

export default {
    namespaced: true,
    state: {
        showCaptcha: false, // v2
        v2WidgetId: null,
        v2Token: null,
        v3Token: null,
    },
    actions: {
        gaV2Token({ state }) {
            /// wait until to get v2WidgetId
            // while( state.v2WidgetId === null) { await sleep(100); }
            return window.grecaptcha.enterprise.getResponse(state.v2WidgetId);
        },
        async gaV3Token({ commit }, action) {
            const token = await recaptchaV3Wrapper.getRecaptchaV3Token(this._vm, action).catch((err) => {
                console.error(err);
                return Promise.reject({ errorCode: 'captcha' });
            });
            commit('setV3Token', token);
            return token;
        },
        async getToken({ state, dispatch }, action) {
            if (state.showCaptcha) return [await dispatch('gaV2Token'), 'v2'];
            else return [await dispatch('gaV3Token', action), 'v3'];
        },
    },
    mutations: {
        setShowCaptcha(state, payload) {
            state.showCaptcha = payload;
        },
        setV2WidgetId(state, payload) {
            state.v2WidgetId = payload;
        },
        setV2Token(state, payload) {
            state.v2Token = payload;
        },
        setV3Token(state, payload) {
            state.v3Token = payload;
        },
    },
};
