import axios from 'axios';

const cdnApi = axios.create({
    baseURL: `https://${process.env.VUE_APP_CDN_HOST}/`,
});

cdnApi.interceptors.request.use(
    (config) => {
        if (config.url) config.url = config.url.replace('{regionCode}', environment.region);
        return config;
    },
    (error) => Promise.reject(error)
);

const getGameList = (cmsRegionCode) => cdnApi.get(`frontend/v3/${cmsRegionCode}/gamelist.json`);

export default {
    ...cdnApi,
    getGameList,
};
