import Vue from 'vue';

import VueI18n from 'vue-i18n';
import enUs from '@/locale/en.json';
import axios from 'axios';
import store from '@/store';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'en',
    silentTranslationWarn: true,
    fallbackLocale: 'en',
    messages: {
        en: enUs,
    },
    numberFormats: {
        AED: {
            currency: {
                style: 'currency',
                currency: 'AED',
                currencyDisplay: 'symbol',
            },
        },
        AFN: {
            currency: {
                style: 'currency',
                currency: 'AFN',
                currencyDisplay: 'symbol',
            },
        },
        ALL: {
            currency: {
                style: 'currency',
                currency: 'ALL',
                currencyDisplay: 'symbol',
            },
        },
        AMD: {
            currency: {
                style: 'currency',
                currency: 'AMD',
                currencyDisplay: 'symbol',
            },
        },
        ANG: {
            currency: {
                style: 'currency',
                currency: 'ANG',
                currencyDisplay: 'symbol',
            },
        },
        AOA: {
            currency: {
                style: 'currency',
                currency: 'AOA',
                currencyDisplay: 'symbol',
            },
        },
        ARS: {
            currency: {
                style: 'currency',
                currency: 'ARS',
                currencyDisplay: 'symbol',
            },
        },
        AUD: {
            currency: {
                style: 'currency',
                currency: 'AUD',
                currencyDisplay: 'symbol',
            },
        },
        AWG: {
            currency: {
                style: 'currency',
                currency: 'AWG',
                currencyDisplay: 'symbol',
            },
        },
        AZN: {
            currency: {
                style: 'currency',
                currency: 'AZN',
                currencyDisplay: 'symbol',
            },
        },
        BAM: {
            currency: {
                style: 'currency',
                currency: 'BAM',
                currencyDisplay: 'symbol',
            },
        },
        BBD: {
            currency: {
                style: 'currency',
                currency: 'BBD',
                currencyDisplay: 'symbol',
            },
        },
        BDT: {
            currency: {
                style: 'currency',
                currency: 'BDT',
                currencyDisplay: 'symbol',
            },
        },
        BGN: {
            currency: {
                style: 'currency',
                currency: 'BGN',
                currencyDisplay: 'symbol',
            },
        },
        BHD: {
            currency: {
                style: 'currency',
                currency: 'BHD',
                currencyDisplay: 'symbol',
            },
        },
        BIF: {
            currency: {
                style: 'currency',
                currency: 'BIF',
                currencyDisplay: 'symbol',
            },
        },
        BMD: {
            currency: {
                style: 'currency',
                currency: 'BMD',
                currencyDisplay: 'symbol',
            },
        },
        BND: {
            currency: {
                style: 'currency',
                currency: 'BND',
                currencyDisplay: 'symbol',
            },
        },
        BOB: {
            currency: {
                style: 'currency',
                currency: 'BOB',
                currencyDisplay: 'symbol',
            },
        },
        BOV: {
            currency: {
                style: 'currency',
                currency: 'BOV',
                currencyDisplay: 'symbol',
            },
        },
        BRL: {
            currency: {
                style: 'currency',
                currency: 'BRL',
                currencyDisplay: 'symbol',
            },
        },
        BSD: {
            currency: {
                style: 'currency',
                currency: 'BSD',
                currencyDisplay: 'symbol',
            },
        },
        BTN: {
            currency: {
                style: 'currency',
                currency: 'BTN',
                currencyDisplay: 'symbol',
            },
        },
        BWP: {
            currency: {
                style: 'currency',
                currency: 'BWP',
                currencyDisplay: 'symbol',
            },
        },
        BYN: {
            currency: {
                style: 'currency',
                currency: 'BYN',
                currencyDisplay: 'symbol',
            },
        },
        BZD: {
            currency: {
                style: 'currency',
                currency: 'BZD',
                currencyDisplay: 'symbol',
            },
        },
        CAD: {
            currency: {
                style: 'currency',
                currency: 'CAD',
                currencyDisplay: 'symbol',
            },
        },
        CDF: {
            currency: {
                style: 'currency',
                currency: 'CDF',
                currencyDisplay: 'symbol',
            },
        },
        CHE: {
            currency: {
                style: 'currency',
                currency: 'CHE',
                currencyDisplay: 'symbol',
            },
        },
        CHF: {
            currency: {
                style: 'currency',
                currency: 'CHF',
                currencyDisplay: 'symbol',
            },
        },
        CHW: {
            currency: {
                style: 'currency',
                currency: 'CHW',
                currencyDisplay: 'symbol',
            },
        },
        CLF: {
            currency: {
                style: 'currency',
                currency: 'CLF',
                currencyDisplay: 'symbol',
            },
        },
        CLP: {
            currency: {
                style: 'currency',
                currency: 'CLP',
                currencyDisplay: 'symbol',
            },
        },
        CNY: {
            currency: {
                style: 'currency',
                currency: 'CNY',
                currencyDisplay: 'symbol',
            },
        },
        COP: {
            currency: {
                style: 'currency',
                currency: 'COP',
                currencyDisplay: 'symbol',
            },
        },
        COU: {
            currency: {
                style: 'currency',
                currency: 'COU',
                currencyDisplay: 'symbol',
            },
        },
        CRC: {
            currency: {
                style: 'currency',
                currency: 'CRC',
                currencyDisplay: 'symbol',
            },
        },
        CUC: {
            currency: {
                style: 'currency',
                currency: 'CUC',
                currencyDisplay: 'symbol',
            },
        },
        CUP: {
            currency: {
                style: 'currency',
                currency: 'CUP',
                currencyDisplay: 'symbol',
            },
        },
        CVE: {
            currency: {
                style: 'currency',
                currency: 'CVE',
                currencyDisplay: 'symbol',
            },
        },
        CZK: {
            currency: {
                style: 'currency',
                currency: 'CZK',
                currencyDisplay: 'symbol',
            },
        },
        DJF: {
            currency: {
                style: 'currency',
                currency: 'DJF',
                currencyDisplay: 'symbol',
            },
        },
        DKK: {
            currency: {
                style: 'currency',
                currency: 'DKK',
                currencyDisplay: 'symbol',
            },
        },
        DOP: {
            currency: {
                style: 'currency',
                currency: 'DOP',
                currencyDisplay: 'symbol',
            },
        },
        DZD: {
            currency: {
                style: 'currency',
                currency: 'DZD',
                currencyDisplay: 'symbol',
            },
        },
        EGP: {
            currency: {
                style: 'currency',
                currency: 'EGP',
                currencyDisplay: 'symbol',
            },
        },
        ERN: {
            currency: {
                style: 'currency',
                currency: 'ERN',
                currencyDisplay: 'symbol',
            },
        },
        ETB: {
            currency: {
                style: 'currency',
                currency: 'ETB',
                currencyDisplay: 'symbol',
            },
        },
        EUR: {
            currency: {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: 'symbol',
            },
        },
        FJD: {
            currency: {
                style: 'currency',
                currency: 'FJD',
                currencyDisplay: 'symbol',
            },
        },
        FKP: {
            currency: {
                style: 'currency',
                currency: 'FKP',
                currencyDisplay: 'symbol',
            },
        },
        GBP: {
            currency: {
                style: 'currency',
                currency: 'GBP',
                currencyDisplay: 'symbol',
            },
        },
        GEL: {
            currency: {
                style: 'currency',
                currency: 'GEL',
                currencyDisplay: 'symbol',
            },
        },
        GHS: {
            currency: {
                style: 'currency',
                currency: 'GHS',
                currencyDisplay: 'symbol',
            },
        },
        GIP: {
            currency: {
                style: 'currency',
                currency: 'GIP',
                currencyDisplay: 'symbol',
            },
        },
        GMD: {
            currency: {
                style: 'currency',
                currency: 'GMD',
                currencyDisplay: 'symbol',
            },
        },
        GNF: {
            currency: {
                style: 'currency',
                currency: 'GNF',
                currencyDisplay: 'symbol',
            },
        },
        GTQ: {
            currency: {
                style: 'currency',
                currency: 'GTQ',
                currencyDisplay: 'symbol',
            },
        },
        GYD: {
            currency: {
                style: 'currency',
                currency: 'GYD',
                currencyDisplay: 'symbol',
            },
        },
        HKD: {
            currency: {
                style: 'currency',
                currency: 'HKD',
                currencyDisplay: 'symbol',
            },
        },
        HNL: {
            currency: {
                style: 'currency',
                currency: 'HNL',
                currencyDisplay: 'symbol',
            },
        },
        HRK: {
            currency: {
                style: 'currency',
                currency: 'HRK',
                currencyDisplay: 'symbol',
            },
        },
        HTG: {
            currency: {
                style: 'currency',
                currency: 'HTG',
                currencyDisplay: 'symbol',
            },
        },
        HUF: {
            currency: {
                style: 'currency',
                currency: 'HUF',
                currencyDisplay: 'symbol',
            },
        },
        IDR: {
            currency: {
                style: 'currency',
                currency: 'IDR',
                currencyDisplay: 'symbol',
            },
        },
        ILS: {
            currency: {
                style: 'currency',
                currency: 'ILS',
                currencyDisplay: 'symbol',
            },
        },
        INR: {
            currency: {
                style: 'currency',
                currency: 'INR',
                currencyDisplay: 'symbol',
            },
        },
        IQD: {
            currency: {
                style: 'currency',
                currency: 'IQD',
                currencyDisplay: 'symbol',
            },
        },
        IRR: {
            currency: {
                style: 'currency',
                currency: 'IRR',
                currencyDisplay: 'symbol',
            },
        },
        ISK: {
            currency: {
                style: 'currency',
                currency: 'ISK',
                currencyDisplay: 'symbol',
            },
        },
        JMD: {
            currency: {
                style: 'currency',
                currency: 'JMD',
                currencyDisplay: 'symbol',
            },
        },
        JOD: {
            currency: {
                style: 'currency',
                currency: 'JOD',
                currencyDisplay: 'symbol',
            },
        },
        JPY: {
            currency: {
                style: 'currency',
                currency: 'JPY',
                currencyDisplay: 'symbol',
            },
        },
        KES: {
            currency: {
                style: 'currency',
                currency: 'KES',
                currencyDisplay: 'symbol',
            },
        },
        KGS: {
            currency: {
                style: 'currency',
                currency: 'KGS',
                currencyDisplay: 'symbol',
            },
        },
        KHR: {
            currency: {
                style: 'currency',
                currency: 'KHR',
                currencyDisplay: 'symbol',
            },
        },
        KMF: {
            currency: {
                style: 'currency',
                currency: 'KMF',
                currencyDisplay: 'symbol',
            },
        },
        KPW: {
            currency: {
                style: 'currency',
                currency: 'KPW',
                currencyDisplay: 'symbol',
            },
        },
        KRW: {
            currency: {
                style: 'currency',
                currency: 'KRW',
                currencyDisplay: 'symbol',
            },
        },
        KWD: {
            currency: {
                style: 'currency',
                currency: 'KWD',
                currencyDisplay: 'symbol',
            },
        },
        KYD: {
            currency: {
                style: 'currency',
                currency: 'KYD',
                currencyDisplay: 'symbol',
            },
        },
        KZT: {
            currency: {
                style: 'currency',
                currency: 'KZT',
                currencyDisplay: 'symbol',
            },
        },
        LAK: {
            currency: {
                style: 'currency',
                currency: 'LAK',
                currencyDisplay: 'symbol',
            },
        },
        LBP: {
            currency: {
                style: 'currency',
                currency: 'LBP',
                currencyDisplay: 'symbol',
            },
        },
        LKR: {
            currency: {
                style: 'currency',
                currency: 'LKR',
                currencyDisplay: 'symbol',
            },
        },
        LRD: {
            currency: {
                style: 'currency',
                currency: 'LRD',
                currencyDisplay: 'symbol',
            },
        },
        LSL: {
            currency: {
                style: 'currency',
                currency: 'LSL',
                currencyDisplay: 'symbol',
            },
        },
        LYD: {
            currency: {
                style: 'currency',
                currency: 'LYD',
                currencyDisplay: 'symbol',
            },
        },
        MAD: {
            currency: {
                style: 'currency',
                currency: 'MAD',
                currencyDisplay: 'symbol',
            },
        },
        MDL: {
            currency: {
                style: 'currency',
                currency: 'MDL',
                currencyDisplay: 'symbol',
            },
        },
        MGA: {
            currency: {
                style: 'currency',
                currency: 'MGA',
                currencyDisplay: 'symbol',
            },
        },
        MKD: {
            currency: {
                style: 'currency',
                currency: 'MKD',
                currencyDisplay: 'symbol',
            },
        },
        MMK: {
            currency: {
                style: 'currency',
                currency: 'MMK',
                currencyDisplay: 'symbol',
            },
        },
        MNT: {
            currency: {
                style: 'currency',
                currency: 'MNT',
                currencyDisplay: 'symbol',
            },
        },
        MOP: {
            currency: {
                style: 'currency',
                currency: 'MOP',
                currencyDisplay: 'symbol',
            },
        },
        MUR: {
            currency: {
                style: 'currency',
                currency: 'MUR',
                currencyDisplay: 'symbol',
            },
        },
        MVR: {
            currency: {
                style: 'currency',
                currency: 'MVR',
                currencyDisplay: 'symbol',
            },
        },
        MWK: {
            currency: {
                style: 'currency',
                currency: 'MWK',
                currencyDisplay: 'symbol',
            },
        },
        MXN: {
            currency: {
                style: 'currency',
                currency: 'MXN',
                currencyDisplay: 'symbol',
            },
        },
        MXV: {
            currency: {
                style: 'currency',
                currency: 'MXV',
                currencyDisplay: 'symbol',
            },
        },
        MYR: {
            currency: {
                style: 'currency',
                currency: 'MYR',
                currencyDisplay: 'symbol',
            },
        },
        MZN: {
            currency: {
                style: 'currency',
                currency: 'MZN',
                currencyDisplay: 'symbol',
            },
        },
        NAD: {
            currency: {
                style: 'currency',
                currency: 'NAD',
                currencyDisplay: 'symbol',
            },
        },
        NGN: {
            currency: {
                style: 'currency',
                currency: 'NGN',
                currencyDisplay: 'symbol',
            },
        },
        NIO: {
            currency: {
                style: 'currency',
                currency: 'NIO',
                currencyDisplay: 'symbol',
            },
        },
        NOK: {
            currency: {
                style: 'currency',
                currency: 'NOK',
                currencyDisplay: 'symbol',
            },
        },
        NPR: {
            currency: {
                style: 'currency',
                currency: 'NPR',
                currencyDisplay: 'symbol',
            },
        },
        NZD: {
            currency: {
                style: 'currency',
                currency: 'NZD',
                currencyDisplay: 'symbol',
            },
        },
        OMR: {
            currency: {
                style: 'currency',
                currency: 'OMR',
                currencyDisplay: 'symbol',
            },
        },
        PAB: {
            currency: {
                style: 'currency',
                currency: 'PAB',
                currencyDisplay: 'symbol',
            },
        },
        PEN: {
            currency: {
                style: 'currency',
                currency: 'PEN',
                currencyDisplay: 'symbol',
            },
        },
        PGK: {
            currency: {
                style: 'currency',
                currency: 'PGK',
                currencyDisplay: 'symbol',
            },
        },
        PHP: {
            currency: {
                style: 'currency',
                currency: 'PHP',
                currencyDisplay: 'symbol',
            },
        },
        PKR: {
            currency: {
                style: 'currency',
                currency: 'PKR',
                currencyDisplay: 'symbol',
            },
        },
        PLN: {
            currency: {
                style: 'currency',
                currency: 'PLN',
                currencyDisplay: 'symbol',
            },
        },
        PYG: {
            currency: {
                style: 'currency',
                currency: 'PYG',
                currencyDisplay: 'symbol',
            },
        },
        QAR: {
            currency: {
                style: 'currency',
                currency: 'QAR',
                currencyDisplay: 'symbol',
            },
        },
        RON: {
            currency: {
                style: 'currency',
                currency: 'RON',
                currencyDisplay: 'symbol',
            },
        },
        RSD: {
            currency: {
                style: 'currency',
                currency: 'RSD',
                currencyDisplay: 'symbol',
            },
        },
        RUB: {
            currency: {
                style: 'currency',
                currency: 'RUB',
                currencyDisplay: 'symbol',
            },
        },
        RWF: {
            currency: {
                style: 'currency',
                currency: 'RWF',
                currencyDisplay: 'symbol',
            },
        },
        SAR: {
            currency: {
                style: 'currency',
                currency: 'SAR',
                currencyDisplay: 'symbol',
            },
        },
        SBD: {
            currency: {
                style: 'currency',
                currency: 'SBD',
                currencyDisplay: 'symbol',
            },
        },
        SCR: {
            currency: {
                style: 'currency',
                currency: 'SCR',
                currencyDisplay: 'symbol',
            },
        },
        SDG: {
            currency: {
                style: 'currency',
                currency: 'SDG',
                currencyDisplay: 'symbol',
            },
        },
        SEK: {
            currency: {
                style: 'currency',
                currency: 'SEK',
                currencyDisplay: 'symbol',
            },
        },
        SGD: {
            currency: {
                style: 'currency',
                currency: 'SGD',
                currencyDisplay: 'symbol',
            },
        },
        SHP: {
            currency: {
                style: 'currency',
                currency: 'SHP',
                currencyDisplay: 'symbol',
            },
        },
        SLL: {
            currency: {
                style: 'currency',
                currency: 'SLL',
                currencyDisplay: 'symbol',
            },
        },
        SOS: {
            currency: {
                style: 'currency',
                currency: 'SOS',
                currencyDisplay: 'symbol',
            },
        },
        SRD: {
            currency: {
                style: 'currency',
                currency: 'SRD',
                currencyDisplay: 'symbol',
            },
        },
        SSP: {
            currency: {
                style: 'currency',
                currency: 'SSP',
                currencyDisplay: 'symbol',
            },
        },
        SVC: {
            currency: {
                style: 'currency',
                currency: 'SVC',
                currencyDisplay: 'symbol',
            },
        },
        SYP: {
            currency: {
                style: 'currency',
                currency: 'SYP',
                currencyDisplay: 'symbol',
            },
        },
        SZL: {
            currency: {
                style: 'currency',
                currency: 'SZL',
                currencyDisplay: 'symbol',
            },
        },
        THB: {
            currency: {
                style: 'currency',
                currency: 'THB',
                currencyDisplay: 'symbol',
            },
        },
        TJS: {
            currency: {
                style: 'currency',
                currency: 'TJS',
                currencyDisplay: 'symbol',
            },
        },
        TMT: {
            currency: {
                style: 'currency',
                currency: 'TMT',
                currencyDisplay: 'symbol',
            },
        },
        TND: {
            currency: {
                style: 'currency',
                currency: 'TND',
                currencyDisplay: 'symbol',
            },
        },
        TOP: {
            currency: {
                style: 'currency',
                currency: 'TOP',
                currencyDisplay: 'symbol',
            },
        },
        TRY: {
            currency: {
                style: 'currency',
                currency: 'TRY',
                currencyDisplay: 'symbol',
            },
        },
        TTD: {
            currency: {
                style: 'currency',
                currency: 'TTD',
                currencyDisplay: 'symbol',
            },
        },
        TWD: {
            currency: {
                style: 'currency',
                currency: 'TWD',
                currencyDisplay: 'symbol',
            },
        },
        TZS: {
            currency: {
                style: 'currency',
                currency: 'TZS',
                currencyDisplay: 'symbol',
            },
        },
        UAH: {
            currency: {
                style: 'currency',
                currency: 'UAH',
                currencyDisplay: 'symbol',
            },
        },
        UGX: {
            currency: {
                style: 'currency',
                currency: 'UGX',
                currencyDisplay: 'symbol',
            },
        },
        USD: {
            currency: {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'symbol',
            },
        },
        USN: {
            currency: {
                style: 'currency',
                currency: 'USN',
                currencyDisplay: 'symbol',
            },
        },
        UYI: {
            currency: {
                style: 'currency',
                currency: 'UYI',
                currencyDisplay: 'symbol',
            },
        },
        UYU: {
            currency: {
                style: 'currency',
                currency: 'UYU',
                currencyDisplay: 'symbol',
            },
        },
        UZS: {
            currency: {
                style: 'currency',
                currency: 'UZS',
                currencyDisplay: 'symbol',
            },
        },
        VEF: {
            currency: {
                style: 'currency',
                currency: 'VEF',
                currencyDisplay: 'symbol',
            },
        },
        VND: {
            currency: {
                style: 'currency',
                currency: 'VND',
                currencyDisplay: 'symbol',
            },
        },
        VUV: {
            currency: {
                style: 'currency',
                currency: 'VUV',
                currencyDisplay: 'symbol',
            },
        },
        WST: {
            currency: {
                style: 'currency',
                currency: 'WST',
                currencyDisplay: 'symbol',
            },
        },
        XAF: {
            currency: {
                style: 'currency',
                currency: 'XAF',
                currencyDisplay: 'symbol',
            },
        },
        XAG: {
            currency: {
                style: 'currency',
                currency: 'XAG',
                currencyDisplay: 'symbol',
            },
        },
        XAU: {
            currency: {
                style: 'currency',
                currency: 'XAU',
                currencyDisplay: 'symbol',
            },
        },
        XBA: {
            currency: {
                style: 'currency',
                currency: 'XBA',
                currencyDisplay: 'symbol',
            },
        },
        XBB: {
            currency: {
                style: 'currency',
                currency: 'XBB',
                currencyDisplay: 'symbol',
            },
        },
        XBC: {
            currency: {
                style: 'currency',
                currency: 'XBC',
                currencyDisplay: 'symbol',
            },
        },
        XBD: {
            currency: {
                style: 'currency',
                currency: 'XBD',
                currencyDisplay: 'symbol',
            },
        },
        XCD: {
            currency: {
                style: 'currency',
                currency: 'XCD',
                currencyDisplay: 'symbol',
            },
        },
        XDR: {
            currency: {
                style: 'currency',
                currency: 'XDR',
                currencyDisplay: 'symbol',
            },
        },
        XOF: {
            currency: {
                style: 'currency',
                currency: 'XOF',
                currencyDisplay: 'symbol',
            },
        },
        XPD: {
            currency: {
                style: 'currency',
                currency: 'XPD',
                currencyDisplay: 'symbol',
            },
        },
        XPF: {
            currency: {
                style: 'currency',
                currency: 'XPF',
                currencyDisplay: 'symbol',
            },
        },
        XPT: {
            currency: {
                style: 'currency',
                currency: 'XPT',
                currencyDisplay: 'symbol',
            },
        },
        XSU: {
            currency: {
                style: 'currency',
                currency: 'XSU',
                currencyDisplay: 'symbol',
            },
        },
        XTS: {
            currency: {
                style: 'currency',
                currency: 'XTS',
                currencyDisplay: 'symbol',
            },
        },
        XUA: {
            currency: {
                style: 'currency',
                currency: 'XUA',
                currencyDisplay: 'symbol',
            },
        },
        XXX: {
            currency: {
                style: 'currency',
                currency: 'XXX',
                currencyDisplay: 'symbol',
            },
        },
        YER: {
            currency: {
                style: 'currency',
                currency: 'YER',
                currencyDisplay: 'symbol',
            },
        },
        ZAR: {
            currency: {
                style: 'currency',
                currency: 'ZAR',
                currencyDisplay: 'symbol',
            },
        },
        ZMW: {
            currency: {
                style: 'currency',
                currency: 'ZMW',
                currencyDisplay: 'symbol',
            },
        },
        ZWL: {
            currency: {
                style: 'currency',
                currency: 'ZWL',
                currencyDisplay: 'symbol',
            },
        },
    },
});

const loadedLanguages = ['en']; // our default language that is preloaded

function setI18nLanguage(lang) {
    i18n.locale = lang;
    store.commit('setLang', lang);
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
    return lang;
}

export function loadLanguageAsync(lang) {
    if (i18n.locale !== lang) {
        if (!loadedLanguages.includes(lang)) {
            return import(/* webpackChunkName: "lang-[request]" */ `@/locale/${lang}.json`).then((msgs) => {
                i18n.setLocaleMessage(lang, msgs.default);
                loadedLanguages.push(lang);
                return setI18nLanguage(lang);
            });
        }
        return Promise.resolve(setI18nLanguage(lang));
    }
    return Promise.resolve(lang);
}
