import { create } from 'vue-modal-dialogs';
import store from '@/store';
import ConfirmUI from '@/components/dialogs/confirm';
import ErrorUI from '@/components/dialogs/error';
import SuccessUI from '@/components/dialogs/success';
import PortalBanUI from '@/components/dialogs/portalban.vue';
import BackupCodesUI from '@/components/dialogs/setting/modal-backup-codes';
import LegacyEmail from '@/components/dialogs/legacy/email';
import LegacySupport from '@/components/dialogs/legacy/support';
import TrustedDevice from '@/components/dialogs/trusted-device';
import TrustedDeviceEditName from '@/components/dialogs/trusted-device-edit-name';
import TrustedDeviceRemove from '@/components/dialogs/trusted-device-remove';
import MfaSuccessUI from '@/components/dialogs/setting/modal-mfa-success';
import SetPrimaryMethodUI from '@/components/dialogs/setting/modal-primary-method';
import EnableSmsConfirmUI from '@/components/dialogs/setting/modal-enable-sms-confirm';
import verifyAccount from '@/plugins/verify-account';
import CreateAccountUI from '@/components/dialogs/create-account';
import NeedPasswordUI from '@/components/dialogs/need-password';
import FamilyMember from '@/components/dialogs/setting/parental-controls/family-member';
import SmsOptInLoginCreate from '@/components/dialogs/sms-opt-in-login-create.vue';
import minorLinking from '@/components/dialogs/minor-linking.vue';

const backupCodesDialog = create(BackupCodesUI),
    enableSmsConfirmDialog = create(EnableSmsConfirmUI),
    setPrimaryMethodDialog = create(SetPrimaryMethodUI),
    mfaSuccessDialog = create(MfaSuccessUI),
    successDialog = create(SuccessUI),
    confirmDialog = create(ConfirmUI),
    portalBanDialog = create(PortalBanUI),
    legacyEmail = create(LegacyEmail),
    legacySupport = create(LegacySupport),
    trustedDevice = create(TrustedDevice),
    trustedDeviceEditName = create(TrustedDeviceEditName),
    trustedDeviceRemove = create(TrustedDeviceRemove),
    createAccountDialog = create(CreateAccountUI),
    needPasswordDialog = create(NeedPasswordUI),
    errorDialog = create(ErrorUI, 'errorCode', 'i18nData'),
    familyMember = create(FamilyMember),
    smsOptInLoginCreate = create(SmsOptInLoginCreate),
    minorLinkingDialog = create(minorLinking, 'platforms'),
    errorDialogHandler = (errorCode, i18nData) => {
        if (process.env.VUE_APP_ENV !== 'prod') console.error(errorCode);
        switch (errorCode) {
            case 20126: // NEED_TO_SET_THE_PASSWORD
                return confirmDialog({
                    title: 'error.modal.NEED_TO_SET_PASSWORD.title',
                    content: 'error.modal.NEED_TO_SET_PASSWORD.desc',
                    confirmLabel: 'error.modal.NEED_TO_SET_PASSWORD.button',
                    confirmFunction: () => {
                        verifyAccount({
                            routeTo: 'create-change-password',
                            reasonCode: 5,
                            emailRequest: {
                                route: '/reset/request-reset',
                                params: { id: store.state.settings.email },
                            },
                        });
                    },
                    cancelLabel: 'cancel',
                });
            default:
                return errorDialog(errorCode, i18nData);
        }
    },
    dialogHelper = {
        install(vue) {
            vue.prototype.$dialog = {
                confirm: confirmDialog,
                error: errorDialogHandler,
                success: successDialog,
                portalBan: portalBanDialog,
                backupCodes: backupCodesDialog,
                legacyEmail,
                legacySupport,
                trustedDevice,
                trustedDeviceEditName,
                trustedDeviceRemove,
                mfaSuccess: mfaSuccessDialog,
                setPrimaryMethod: setPrimaryMethodDialog,
                enableSmsConfirm: enableSmsConfirmDialog,
                createAccount: createAccountDialog,
                setPassword: needPasswordDialog,
                familyMember,
                smsOptInLoginCreate,
                minorLinking: minorLinkingDialog,
            };
        },
    };

export default dialogHelper;
