import router from '@/router';
import { getBlackbox } from '@arena/toolkit/src/plugins/iovation';

export default {
    namespaced: true,
    state: {
        tpaInit: {
            facebook: false,
        },
        provider: '',
        email: '', // nexon account email
    },
    actions: {
        // tpa session
        async initLogin({ rootState, commit }, provider) {
            commit('setProvider', provider);

            if (provider === 'steam-connector' && rootState.isApplication) {
                const data = await this._vm.$accountAPI.post('/no-auth/login/steam/tpa-session-cookie', {
                    clientId: parent.settings.appID,
                    steamSessionTicket: parent.settings.steamSessionTicket,
                });

                commit('setEmail', data.email); // only steam provides email info
            } else {
                const code =
                    provider === 'steam'
                        ? router.currentRoute.query['openid.identity']
                        : router.currentRoute.query.code;
                await this._vm.$accountAPI.post(
                    `/no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}login/tpa/tpa-session-cookie`,
                    {
                        tpaType: provider,
                        code,
                        ...(provider === 'twitter' ? { state: router.currentRoute.query.state } : {}),
                    }
                );
            }
        },
        // tpa session to login
        async login({ rootState, dispatch }) {
            if (router.currentRoute.query.parental_flow_mode === 'child') {
                await dispatch('loginChild');
            } else if (rootState.isApplication) {
                await dispatch('login/launcher/loginTpa', null, { root: true });
            } else {
                await dispatch('loginWeb');
            }
        },
        async loginWeb({ rootGetters, dispatch }) {
            const data = await this._vm.$accountAPI.post(
                `/no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}login/tpa`,
                {
                    deviceId: rootGetters.deviceId,
                    localTime: Date.now(),
                    timeOffset: new Date().getTimezoneOffset(),
                }
            );

            await dispatch('login/afterLoginCommon', data, { root: true });
        },
        async loginChild() {
            await this._vm.$accountSettingAPI.post('/parental/child/login/tpa');

            router.replace({ name: 'add-child-complete' });
        },
        // nexon account email
        async getUserEmail({ commit }) {
            const data = await this._vm.$accountAPI.get('/no-auth/tpa/session/email');
            if (data.email) commit('setEmail', data.email);
            return data.email;
        },
        async signUpTpa({ rootState, dispatch }) {
            async function waitForBlackbox(retriesLeft = 10, interval = 300) {
                const { blackbox, finished } = await getBlackbox({ version: '5.2.2' });
                if (finished) {
                    return blackbox;
                } else if (retriesLeft) {
                    await new Promise((r) => setTimeout(r, interval));
                    return waitForBlackbox(retriesLeft - 1, interval);
                } else return blackbox;
            }
            let blackbox = await waitForBlackbox();
            await this._vm.$accountAPI.post(
                `/no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}signup-tpa-with-email`,
                {
                    id: rootState.login.loginId,
                    password: rootState.login.hashedPassword,
                    blackbox,
                }
            );
            await dispatch('login');
        },
    },
    mutations: {
        setTpaInit(state, payload) {
            if (typeof payload !== 'object') return;
            state.tpaInit = Object.assign({}, state.tpaInit, payload);
        },
        setProvider(state, payload) {
            state.provider = payload;
        },
        setEmail(state, payload) {
            state.email = payload;
        },
    },
};
