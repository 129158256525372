<template>
    <div class="message-box dialog-mask backup-codes">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('settings.modal.backup-codes.title') }}</h4>
                </div>
                <div class="modal-body">
                    <p>
                        {{ description
                        }}<span
                            v-if="verified"
                            class="generate-new"
                            data-cy="generate-new-backup-codes"
                            @click="generateNewCodes"
                            >{{ $t('settings.modal.backup-codes.verified.more') }}</span
                        >
                    </p>
                    <div class="input-container">
                        <div v-if="!verified">
                            <FormInput
                                id="password"
                                v-model="inputPassword"
                                data-cy="backup-codes-password"
                                type="password"
                                :min-length="8"
                                :max-length="32"
                                :submitted="submitted"
                                :enter-handler="verify"
                                :placeholder="$t('login.password')"
                                @validation-change="setPasswordValidationStatus($event)"
                            >
                            </FormInput>
                        </div>
                        <div v-else class="codes">
                            <div v-if="loading" class="page-spinner-wrapper">
                                <span class="spinner-circle"></span>
                            </div>
                            <div v-if="!loading" class="list" data-cy="backup-codes-list">
                                <span
                                    v-for="(code, $index) in codes"
                                    :key="$index"
                                    class="code"
                                    :class="{ disabled: code.used }"
                                >
                                    <span class="half">{{ code.backupCode.slice(0, 4) }}</span
                                    >{{ code.backupCode.slice(4, 8)
                                    }}<span class="used">{{ $t('settings.modal.backup-codes.code-used') }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        data-cy="buttons"
                        :btn-primary-id="verified ? 'downCode' : 'verify'"
                        :loading="loading"
                        :btn-primary-text="primaryBt"
                        :btn-secondary-text="$t('close')"
                        :primary-button-click="verified ? download : verify"
                        :secondary-button-click="closeModal"
                        btn-secondary-id="close"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import sha512 from 'crypto-js/sha512';
import { saveAs } from 'file-saver';
import { ModalMultiButtons, FormInput } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    name: 'ModalBackupCodes',
    components: {
        ModalMultiButtons,
        FormInput,
    },
    mixins: [modalEscMixin],
    data() {
        return {
            loading: false,
            verified: false,
            inputPassword: '',
            validPassword: null,
            submitted: false,
            codes: null,
        };
    },
    computed: {
        ...mapGetters('settings', ['authType']),
        description() {
            return this.$t(`${this.getTranslationKey()}.desc`);
        },
        primaryBt() {
            return this.$t(`${this.getTranslationKey()}.button`);
        },
        auth2FAEventType() {
            switch (this.authType) {
                case 'TOTP':
                    return 'App';
                case 'SMS':
                    return 'SMS';
                default:
                    return 'Email';
            }
        },
    },
    created() {
        this.handleEscCallbackToMixin = this.closeModal;
    },
    methods: {
        getTranslationKey() {
            return `settings.modal.backup-codes.${this.verified ? 'verified' : 'unverified'}`;
        },
        closeModal() {
            this.$close(true);
        },
        download() {
            dataLayer.push({ event: '2FAdownload' });

            const backupCodesText = this.createTextFile(),
                fileName = `${this.$t('settings.modal.backup-codes.file.title').replace(/\s/g, '')}.txt`,
                blob = new Blob([backupCodesText], { type: 'text/plain;charset=utf-8' });

            saveAs(blob, fileName);

            this.closeModal();
        },
        createTextFile() {
            if (!this.codes || this.codes.length === 0) return '';
            let file = this.getFileHeader();
            const len = this.codes.length;
            for (let i = 0; i < len; i++) {
                const code = this.codes[i];
                file = `${file}\r\n${code.backupCode}${
                    code.used ? ` - ${this.$t('settings.modal.backup-codes.code-used')}` : ''
                }`;
            }
            return file;
        },
        getFileHeader() {
            return `**********${this.$t('settings.modal.backup-codes.file.title')}**********\r\n${this.$t(
                'settings.modal.backup-codes.file.desc',
                {
                    date: new Date(Date.now()).toLocaleString([this.$i18n.locale, 'en'], {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                    }),
                }
            )}\r\n`;
        },
        verify() {
            this.loading = true;
            this.submitted = true;
            this.$accountAPI
                .post('/mfa/totp/backup-code', {
                    password: sha512(this.inputPassword).toString(),
                })
                .then((data) => {
                    dataLayer.push({
                        event: '2FA Backup Codes Generated',
                        '2FAType': this.auth2FAEventType,
                    });
                    this.verified = true;
                    this.codes = data;
                })
                .catch(({ errorCode }) => {
                    this.$dialog.error(errorCode);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        generateNewCodes() {
            this.loading = true;
            this.$accountAPI
                .put('/mfa/totp/backup-code', {
                    password: sha512(this.inputPassword).toString(),
                })
                .then((data) => {
                    dataLayer.push({
                        event: '2FA Backup Codes Generated',
                        '2FAType': this.auth2FAEventType,
                    });
                    if (data) {
                        this.codes = data;
                    }
                })
                .catch(({ errorCode }) => {
                    this.$dialog.error(errorCode);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setPasswordValidationStatus(isValidated) {
            this.validPassword = !isValidated;
        },
    },
};
</script>

<style lang="less">
.backup-codes {
    .modal-header {
        padding-bottom: 20px;
    }
    .generate-new {
        cursor: pointer;
        font-size: 12px;
        margin-left: 10px;
        text-decoration: underline;
    }
    .input-container {
        align-items: center;
        display: flex;
        justify-content: center;
        input {
            margin-top: 10px;
            width: 320px;
        }
        .codes {
            align-items: center;
            background-color: #0c0c0c;
            display: flex;
            height: 149px;
            justify-content: center;
            width: 100%;
            .list {
                align-items: center;
                color: white;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                height: 100%;
                justify-content: space-between;
                padding: 20px;
                width: 75%;
                .code {
                    height: 19px;
                    &.disabled {
                        color: #808080;
                        .used {
                            opacity: 1;
                        }
                    }
                    .half {
                        padding-right: 5px;
                    }
                    .used {
                        font-size: 12px;
                        margin-left: 10px;
                        opacity: 0;
                    }
                }
            }
            .page-spinner-wrapper {
                top: ~'calc(100% - 100px)';
            }
        }
    }
    .modal-footer {
        padding-top: 0;
    }
}
@media (max-width: 420px) {
    .backup-codes .list {
        width: 100% !important;
    }
}
</style>
