// internal errorCode should be string, server side errorCode should be number

import router from '@/router';
import { decamelizeKeys } from 'humps';
import verifyAccount from '@/plugins/verify-account';

export const accountErrorHandling = (error) => {
    let errorCode = 'unknown';
    if (error.response) {
        const errorData = error.response.data;
        errorCode = parseInt(error.response.headers['x-arena-web-errorcode'], 10);
        console.error(`api error: ${error.config.method} - ${error.config.url} => ${errorCode}`);
        // this is for login redirection due to login required action taken after page routing
        switch (errorCode) {
            case 20001: // LOGIN_REQUIRED
                router.replace({
                    name: 'login',
                    query: {
                        return_url: document.URL,
                    },
                });
                break;
            case 20159: // PF_SESSION_EXPIRED
            case 20138: // NOT_AUTHORIZED already linked
            case 'INVALID_TICKET':
                router.replace({
                    name: 'link-failed',
                    params: { ...router.currentRoute.params },
                });
                break;
            case 20045: // NEED_HEALUP
                {
                    const healups = errorData ? errorData.message.split(',') : [];
                    const isTpa = error.response.config.url.includes('/tpa');
                    router.push({
                        name: 'healup',
                        params: {
                            healups,
                            isSetupPassword: router.currentRoute.params.isSetupPassword,
                        },
                        query: {
                            mode: isTpa ? 'tpa' : 'nx',
                        },
                    });
                }
                break;
            case 20128: // NOT_EXIST_EMAIL_ACCOUNT
                router.replace({
                    name: 'login-social-link',
                    params: { case: '5' },
                });
                break;
            case 20135: // SMS_VERIFIED_REQUIRED
                dataLayer.push({ event: 'SMS verification required' });
                {
                    const maskedPhoneNumber =
                        errorData.message !== 'need sms verification' ? { phoneNumber: errorData.message } : {};
                    router.replace({
                        name: 'verify-phone',
                        params: {
                            ...maskedPhoneNumber,
                            needHealup: true,
                        },
                    });
                }
                break;
            case 20226: // PARENTAL_CONSENT_REQUIRED - under age
                router.push({
                    name: 'parent-consent-required',
                });
                break;
            case 20008: // CODE_VERIFICATOIN_IS_REQUIRED
                {
                    const reasonCode = parseInt(errorData.message.split('missing verification code: ')[1], 10);
                    if (reasonCode && [9, 10].includes(reasonCode)) {
                        const routeTo = reasonCode === 9 ? 'manage-trusted-devices' : 'setting.parental-controls';
                        const cancelTo = reasonCode === 9 ? 'setting.security' : 'setting';
                        verifyAccount({
                            routeTo,
                            reasonCode,
                            emailRequest: {
                                route: '/id/request-verify-email',
                                params: {},
                            },
                            cancelTo,
                        });
                    }
                }
                break;
            case 20248: // IAL session expiration
                window.location.href = `https://${
                    process.env.VUE_APP_ENV == 'test' ? 'test-' : ''
                }signin.nexon.com/pages/link/expired`;
                break;
        }
        return Promise.reject({ errorCode, message: errorData.message });
    } else if (error.message === 'Network Error') errorCode = 'NETWORK_ERROR';
    else if (error.code === 'ECONNABORTED') errorCode = 'TIMEOUT';
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ errorCode });
};

// get method: snake_case
export const paramsFormatter = (request) => {
    // convert all query params keys to snake_case
    if (request.method === 'get' && request.params) {
        request.params = decamelizeKeys(request.params);
    }
    return request;
};

export const selfServiceErrorHandling = (error) => {
    let errorCode = 'unknown';
    if (error.response) {
        const errorData = error.response.data;
        errorCode = parseInt(error.response.headers['x-arena-web-errorcode'], 10);
        console.error(`api error: ${error.config.method} - ${error.config.url} => ${errorCode}`);
        switch (errorCode) {
            case 20208: // AUTH_SESSION_EXPIRED
            case 20209: // INVALID_AUTH_SESSION
                router.replace({
                    name: 'self-service',
                });
                break;

            case 20063: // when user is trying to change email in 7 days cooldown period
                router.replace({
                    name: 'self-change-email-failed-cooldown',
                });
                break;
            default:
                return Promise.reject({ errorCode, message: errorData.message });
        }
    } else if (error.message === 'Network Error') errorCode = 'NETWORK_ERROR';
    else if (error.code === 'ECONNABORTED') errorCode = 'TIMEOUT';
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ errorCode });
};
