// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueJsonp from 'vue-jsonp';
import VueCookie from 'vue-cookie';
import axios from 'axios';
import Meta from 'vue-meta';
import UUID from 'vue-uuid';
import * as ModalDialogs from 'vue-modal-dialogs';
import Vuelidate from 'vuelidate';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Iovation from '@arena/toolkit/src/plugins/iovation';
import App from './App';
import router from './router';
import store from './store';
import '@/plugins';
import { i18n } from './plugins/i18n';
import recaptchaKeys from './config/recaptcha-key';
import Paginate from 'vuejs-paginate';

axios.defaults.xsrfHeaderName = 'xsrf_default_overwritten';

Vue.config.devtools = true;
Vue.config.productionTip = false;

Vue.use(Iovation);
Vue.use(VueJsonp);
Vue.use(VueCookie);
Vue.use(Meta);
Vue.use(ModalDialogs);
Vue.use(Vuelidate);
Vue.use(UUID);
Vue.use(VueReCaptcha, {
    siteKey: recaptchaKeys.v3,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
    },
});

Vue.prototype.$http = axios.create({
    withCredentials: true,
});

Vue.filter('localize', function(value) {
    if (!value) return '';
    return value[this.$i18n.locale] || value[this.$i18n.fallbackLocale] || '';
});

Vue.directive('focus', {
    inserted: function(el) {
        el.focus();
    },
});

Vue.component('paginate', Paginate);

Vue.mixin({
    data() {
        return {
            region: environment.region,
            subRegion: environment.subRegion,
            imgHost: `${process.env.BASE_URL}static/images/`,
        };
    },
    computed: {
        isFromMarvelMachine() {
            const marvelQuery = this.$route.query['marvel-machine'] === 'true';
            const marvelCookie = Vue.cookie.get('FromMarvelMachine') === 'true';
            const { landing } = this.$store.state;
            return (marvelQuery || marvelCookie) && !landing;
        },
    },
    methods: {
        defaultRedirectHandler() {
            store.dispatch('defaultRedirectHandler');
        },
        isTWRegion() {
            return (
                this.region === 'tw' ||
                window.location.host.includes('tw.nexon.com/main/zh') ||
                ((this.region === 'one' || this.region === 'global') && window.location.pathname.includes('/zh'))
            );
        },
    },
});

/* eslint-disable no-new */
window.App = new Vue({
    el: '#app',
    i18n,
    router,
    store,
    silentTranslationWarn: true,
    components: {
        App,
    },
    beforeCreate() {
        this.$store.dispatch('retrieveConfiguration');
    },
    methods: {
        addScript(id, url) {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script'),
                    s = document.getElementsByTagName('script')[0];
                if (document.getElementById(id)) return;
                script.id = id;
                script.src = url;
                script.onload = resolve;
                script.onerror = (event) => {
                    reject(new Error(`Failed to load ${event.target.src}`));
                };
                script.defer = true;
                s.parentNode.insertBefore(script, s);
            });
        },
    },
    render: (h) => h(App),
});
