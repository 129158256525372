import axios from 'axios';
import { paramsFormatter, accountErrorHandling } from './intercepters';

const accountAPI = axios.create({
    baseURL: `/api/account/v1`,
    withCredentials: true,
});

accountAPI.defaults.headers.common['x-arena-fe-version'] = `account-${process.env.VUE_APP_VERSION}`;
if (window.application && window.application.sessionId) {
    accountAPI.defaults.headers.common['x-nxl-session-id'] = window.application.sessionId;
}

accountAPI.interceptors.request.use(paramsFormatter);
accountAPI.interceptors.response.use((response) => response.data, accountErrorHandling);

export default accountAPI;
