<template>
    <div class="message-box dialog-mask portal-ban-dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('error.modal.20100.title') }}</h4>
                </div>
                <div class="modal-body">
                    <p>{{ $t('error.modal.20100.currently-blocked') }}</p>
                    <p v-if="banReason">
                        {{ $t('error.modal.20100.reason') }}
                        <span class="ban-reason">{{ $t(`error.modal.20100.${banReason}`) }}</span>
                    </p>
                    <p>{{ $t('error.modal.20100.details') }}</p>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :btn-primary-text="$t('error.modal.20100.button')"
                        :btn-secondary-text="$t('close')"
                        :primary-button-click="clickSupport"
                        :secondary-button-click="clickCancel"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModalMultiButtons } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        ModalMultiButtons,
    },
    mixins: [modalEscMixin],
    props: {
        inputAccountId: {
            type: String,
            default: '',
        },
        inputPassword: {
            type: String,
            default: '',
        },
        captchaToken: {
            type: String,
            default: '',
        },
        captchaVersion: {
            type: String,
            default: '',
        },
        loginType: {
            type: String,
            default: 'normal',
        },
        banReason: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        isUnified() {
            return this.$router.currentRoute.query.unified || this.$router.currentRoute.name.indexOf('unified') > -1;
        },
    },
    mounted() {
        dataLayer.push({
            event: 'SignupLoginErrorView',
            errorName: 'Account Banned',
        });
    },
    created() {
        this.handleEscCallbackToMixin = this.clickCancel;
    },
    methods: {
        async clickSupport() {
            dataLayer.push({
                event: 'SignupLoginErrorClick',
                errorName: 'Account Banned',
                errorClick: 'Contact Support',
            });
            try {
                if (environment.region === 'global') {
                    let data = null;
                    if (this.loginType === 'normal') {
                        const [captchaToken, captchaVersion] = await this.$store.dispatch(
                            'recaptcha/getToken',
                            this.$store.state.isApplication ? 'launcher_login' : 'web_login'
                        );
                        data = await this.$accountAPI.post(`/no-auth/login/token`, {
                            id: this.inputAccountId,
                            password: this.inputPassword,
                            captchaToken,
                            captchaVersion,
                        });
                    }
                    if (this.loginType === 'social') {
                        data = await this.$accountAPI.post(`/no-auth/login/token/tpa`);
                    }
                    if (data.token) {
                        this.$accountAPI
                            .get(`/no-auth/${this.isUnified ? 'unified/' : ''}login/zendesk?token=${data.token}`)
                            .then(({ returnUrl }) => {
                                const returnURLqueryParam = this.$route.query.return_url;
                                let returnToURLqueryParam = null;
                                if (returnURLqueryParam) {
                                    let matchedReturnURLquery = returnURLqueryParam.match(/return_to=(.*)/);
                                    if (matchedReturnURLquery) returnToURLqueryParam = matchedReturnURLquery[1];
                                }
                                if (returnToURLqueryParam) {
                                    window.open(`${returnUrl}&return_to=${returnURLqueryParam}`, '_blank');
                                } else {
                                    window.open(`${returnUrl}&return_to=https://accountblock.zendesk.com/`, '_blank');
                                }
                            });
                    }
                } else {
                    window.open(this.getRegionSupportUrl(), '_blank');
                }
                this.$close();
            } catch (error) {
                this.$error(error);
            }
        },
        getRegionSupportUrl() {
            let url = '';
            switch (environment.region) {
                case 'th':
                    url = 'https://th.nexon.com/help/th/home';
                    break;
                case 'tw':
                    url = 'https://tw.nexon.com/help/zh/report/new';
                    break;
                case 'sea':
                    url = 'https://sea.nexon.com/help/en';
                    break;
            }
            return url;
        },
        async clickCancel() {
            dataLayer.push({
                event: 'Closed Portal Ban Modal',
                'Portal Ban Reason': this.$t(`error.modal.20100.${this.banReason}`, 'en'),
                'Portal Ban Reason ID': this.banReason,
            });
            this.$close();
        },
    },
};
</script>

<style lang="less" scoped>
.portal-ban-dialog {
    .ban-reason {
        font-weight: bold;
    }
}
</style>
