<template>
    <div class="message-box dialog-mask need-password-dialog">
        <FormError :hide-error="!errorCode" :error-code="errorCode"></FormError>

        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('login.setup-password.title') }}</h4>
                </div>
                <div class="modal-body">
                    <p>{{ $t('login.setup-password.desc-1') }}</p>
                    <p class="input-id">{{ id }}</p>
                    <p>{{ $t('login.setup-password.desc-2') }}</p>
                    <reCaptcha
                        v-if="showCaptcha"
                        v-model="$v.reCaptchaCode.$model"
                        :has-error="$v.reCaptchaCode.$error"
                        :override-error-text="$t('self-service.complete-captcha')"
                        @captcha-completed="setCaptchaCode"
                    >
                    </reCaptcha>
                    <recaptcha-attribution
                        @privacy-click="trackOtherLinkClick('Privacy Policy')"
                        @tos-click="trackOtherLinkClick('Terms of Service')"
                    ></recaptcha-attribution>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :btn-primary-text="$t('create-account.complete.continue')"
                        :btn-secondary-text="$t('cancel')"
                        :primary-button-click="clickCreatePassword"
                        :secondary-button-click="clickCancel"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FormError, ModalMultiButtons } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';
import recaptchaAttribution from '@/components/recaptcha-attribution';
import reCaptcha from '@/components/recaptcha';
import { requiredIf } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
    components: {
        ModalMultiButtons,
        recaptchaAttribution,
        reCaptcha,
        FormError,
    },
    mixins: [modalEscMixin],
    props: {
        id: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return {
            buttonLoading: false,
            showCaptcha: false,
            errorCode: 0,
        };
    },
    validations() {
        return {
            reCaptchaCode: {
                required: requiredIf(() => this.showCaptcha),
            },
        };
    },
    computed: {
        ...mapGetters({
            operationType: 'selfService/getOperationType',
        }),
        isApplication() {
            return this.$store.state.isApplication;
        },
    },
    created() {
        this.handleEscCallbackToMixin = this.clickCancel;
    },
    methods: {
        clickCreatePassword() {
            dataLayer.push({
                event: 'SignupLoginErrorClick',
                errorName: 'Need Password',
                errorClick: 'Create Password',
            });
            this.buttonLoading = true;
            this.$store.commit('selfService/setEnteredEmail', this.id);
            this.$store.commit('selfService/setOperationType', 'resetPassword');

            this.$store
                .dispatch('selfService/getAuthTypes', {
                    searchBy: 'email',
                    value: this.id,
                    captchaAction: this.isApplication ? 'launcher_self_reset_password' : 'web_self_reset_password',
                    isSetupPassword: true, // use for password setup userflow for those who signed up Nexon on STEAM/PSN/Xbox
                })
                .then(() => {
                    this.$close(true);
                })
                .catch(({ errorCode }) => {
                    this.errorHandling(errorCode);
                });
        },
        async clickCancel() {
            this.$close(false);
        },
        trackOtherLinkClick(name) {
            dataLayer.push({
                event: 'accountRecoverySelfService_OtherLinkClick',
                'AR Link Name': name,
            });
        },
        setCaptchaCode() {
            this.reCaptchaCode = window.grecaptcha.enterprise.getResponse(window.rcWidgetId);
        },
        errorHandling(errorCode) {
            this.buttonLoading = false;

            if (errorCode === 20072) {
                this.showCaptcha = true;
                dataLayer.push({
                    event: 'recoveryCaptchaTriggered',
                    'AR Recaptcha Source': this.operationType,
                });
                return;
            }
            this.errorCode = errorCode;
        },
    },
};
</script>

<style lang="less" scoped>
.need-password-dialog {
    color: black;
    .modal-body {
        p.input-id {
            color: red;
            font-weight: bold;
        }
        p:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .recaptcha-attribution {
        margin-top: 40px;
        font-size: 10px;
        color: #737373;
    }

    @media (min-width: 768px) {
        .modal-dialog {
            width: 630px;
        }
    }
}
</style>
