<template>
    <div class="message-box dialog-mask create-account-dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('create-account.create-account') }}</h4>
                </div>
                <div class="modal-body">
                    <p>{{ $t('create-account.not-found') }}</p>
                    <p class="input-id">{{ id }}</p>
                    <p>{{ $t('create-account.ask-to-create') }}</p>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :btn-primary-text="$t(buttonTitle)"
                        :btn-secondary-text="$t('cancel')"
                        :primary-button-click="clickCreateAccount"
                        :secondary-button-click="clickCancel"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModalMultiButtons } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        ModalMultiButtons,
    },
    mixins: [modalEscMixin],
    props: {
        id: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        utmParams() {
            return this.$store.state.utmParams;
        },
        parentalFlowMode() {
            return this.$route.query.parental_flow_mode;
        },
        buttonTitle() {
            switch (this.parentalFlowMode) {
                case 'parent':
                    return 'parental-controls.parent.signup.title';
                case 'child':
                    return 'parental-controls.child.signup.title';
            }
            return 'create-account.create';
        },
        buttonRouteTo() {
            switch (this.parentalFlowMode) {
                case 'parent':
                    return 'create-parent-account';
                case 'child':
                    return 'add-child-signup';
            }
            return 'create-account';
        },
    },
    created() {
        this.handleEscCallbackToMixin = this.clickCancel;
    },
    methods: {
        clickCreateAccount() {
            dataLayer.push({
                event: 'SignupLoginErrorClick',
                errorName: 'Account Not Found',
                errorClick: 'Create An Account',
            });
            this.$close(true);
            this.$router.push({
                name: this.buttonRouteTo,
                query: this.utmParams,
                params: {
                    accountId: this.id,
                },
            });
        },
        async clickCancel() {
            this.$close(false);
        },
    },
};
</script>

<style lang="less" scoped>
.create-account-dialog {
    .modal-body {
        p.input-id {
            color: red;
            font-weight: bold;
        }
        p:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
</style>
