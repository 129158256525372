<template>
    <div class="dialog-mask minor-linking-dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ title }}</h4>
                </div>
                <div class="modal-body">
                    <p>
                        {{ body }}
                        <span v-show="!showDelinkContent" class="link" @click="clickLink">
                            {{ $t('dialog.minor-linking.notice.link') }}
                        </span>
                    </p>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :btn-primary-text="$t('ok')"
                        :btn-secondary-text="$t('cancel')"
                        :primary-button-click="clickOk"
                        :secondary-button-click="clickCancel"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModalMultiButtons } from '@arena/toolkit';

export default {
    components: {
        ModalMultiButtons,
    },
    props: {
        platforms: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            showDelinkContent: false,
        };
    },
    computed: {
        title() {
            return this.showDelinkContent
                ? this.$t('dialog.minor-linking.delink.title')
                : this.$t('dialog.minor-linking.notice.title');
        },
        body() {
            return this.showDelinkContent
                ? `${this.$t('dialog.minor-linking.delink.desc1')}\n${this.platforms}\n${this.$t(
                      'dialog.minor-linking.delink.desc2'
                  )}\n\n${this.$t('dialog.minor-linking.delink.desc3')}\n\n${this.$t(
                      'dialog.minor-linking.delink.desc4'
                  )}`
                : `${this.$t('dialog.minor-linking.notice.desc1')}\n${this.$t(
                      'dialog.minor-linking.notice.desc2'
                  )}\n\n${this.$t('dialog.minor-linking.notice.desc3')}\n${this.$t(
                      'dialog.minor-linking.notice.desc4'
                  )}`;
        },
    },
    methods: {
        async clickOk() {
            this.$close(this.showDelinkContent ? true : false);
            if (!this.showDelinkContent) {
                this.$router.push({
                    name: 'parent-consent-required',
                });
            }
            this.showDelinkContent = false;
        },
        async clickCancel() {
            this.$close(false);
            this.showDelinkContent = false;
        },
        clickLink() {
            this.showDelinkContent = true;
        },
    },
};
</script>
<style lang="less" scoped>
.minor-linking-dialog {
    .modal-dialog {
        width: 640px;
    }
    .modal-body {
        white-space: pre-line;
        text-align: center;
        .link {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>
