<template>
    <div class="message-box dialog-mask family-member-dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('parental-controls.dialog.manage-family-member') }}</h4>
                </div>
                <div class="modal-body">
                    <div v-if="!isMe">
                        <label>{{ $t('status') }}</label>
                        <div>
                            <span>{{ $t(child.status === 1 ? 'active' : 'inactive') }}</span>
                            <span>
                                <button v-if="child.status === 1" data-cy="inactivate-btn" @click="activate(false)">
                                    {{ $t('parental-controls.dialog.change-to-inactive') }}
                                </button>
                                <button v-else class="red" data-cy="activate-btn" @click="activate(true)">
                                    {{ $t('parental-controls.dialog.reactivate') }}
                                </button>
                            </span>
                        </div>
                    </div>

                    <div>
                        <label>{{ $t('email') }}</label>
                        <div>
                            <span>{{ isMe ? `${parent.email} (${$t('you')})` : child.email }}</span>
                        </div>
                    </div>

                    <div>
                        <label>{{ $t('settings.full-name.title') }}</label>
                        <div>
                            <span>{{ isMe ? fullName : child.name }}</span>
                        </div>
                    </div>

                    <div v-if="!isMe && child.accessibleProductId">
                        <label>{{ $t('parental-controls.allowed-games') }}</label>
                        <div>
                            <span>
                                <div v-for="game in childAllowedGames" :key="game.productID">{{ game.name }}</div>
                            </span>
                            <span>
                                <button @click="reset">{{ $t('settings.reset') }}</button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <FormButton
                        v-if="!isMe && child.status === 1"
                        id="release-child"
                        :label="$t('parental-controls.dialog.release-this-account')"
                        :loading="releaseLoading"
                        :button-click="releaseChild"
                        data-cy="release-btn"
                    />
                    <FormButton
                        id="close"
                        :label="$t('close')"
                        class="btn-black"
                        :button-click="closeModal"
                        data-cy="close-btn"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { FormButton } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

function gaClickEvent(title) {
    dataLayer.push({
        event: `${title} Child Account Click`,
    });
}
function gaConfirmEvent(title, result) {
    dataLayer.push({
        event: `${title} Child Account Confirm`,
        parentalControl_Result: result ? 'Success' : 'Failure',
    });
}

export default {
    components: {
        FormButton,
    },
    mixins: [modalEscMixin],
    props: {
        childUserNo: {
            type: Number,
            default: 0,
        },
        isMe: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            releaseLoading: false,
            errorCode: '',
        };
    },
    computed: {
        ...mapState(['gameList']),
        ...mapState('settings', ['fullName']),
        ...mapState('settings/parentalControls', ['parent', 'children']),
        child() {
            return this.children.find((x) => x.globalUserNo === this.childUserNo);
        },
        childAllowedGames() {
            if (!this.child.accessibleProductId) return [];
            return this.child.accessibleProductId
                .split(',')
                .map((prodId) => this.gameList.find((game) => game.productID === prodId))
                .filter((one) => one);
        },
    },
    created() {
        this.getGameList();
        this.handleEscCallbackToMixin = this.closeModal;
    },
    methods: {
        ...mapActions(['getGameList']),
        ...mapActions('settings/parentalControls', ['patchFamilyMember']),
        releaseChild() {
            gaClickEvent('Release');
            this.$dialog.confirm({
                title: 'parental-controls.dialog.release.title',
                content: 'parental-controls.dialog.release.desc',
                confirmLabel: 'confirm',
                confirmFunction: async () => {
                    const result = await this.$accountSettingAPI
                        .delete(`/parental/child/release?child_user_no=${this.child.globalUserNo}`)
                        .then(() => {
                            this.$dialog.success({ message: 'settings.update-success' });
                            return true;
                        })
                        .catch(({ errorCode }) => {
                            this.$dialog.error(errorCode);
                            return false;
                        });

                    gaConfirmEvent('Release', result);
                    await this.patchFamilyMember();
                    this.$close();
                },
            });
        },
        closeModal() {
            this.$close();
        },
        activate(setValue) {
            gaClickEvent(setValue ? 'Reactivate' : 'Inactivate');
            this.$dialog.confirm({
                title: `parental-controls.dialog.${setValue ? 'active' : 'inactive'}.title`,
                content: `parental-controls.dialog.${setValue ? 'active' : 'inactive'}.desc`,
                confirmLabel: 'confirm',
                confirmFunction: async () => {
                    const result = await this.$accountSettingAPI
                        .put('/parental/child/status', {
                            childUserNo: this.child.globalUserNo,
                            active: setValue,
                        })
                        .then(() => true)
                        .catch(({ errorCode }) => {
                            this.$dialog.error(errorCode);
                            return false;
                        });

                    gaConfirmEvent(setValue ? 'Reactivate' : 'Inactivate', result);
                    await this.patchFamilyMember();
                },
            });
        },
        reset() {
            gaClickEvent('Reset');
            this.$dialog.confirm({
                title: 'parental-controls.dialog.reset.title',
                content: 'parental-controls.dialog.reset.desc',
                confirmLabel: 'confirm',
                confirmFunction: async () => {
                    const result = await this.$accountSettingAPI
                        .delete(`/parental/child/game-access/reset?child_user_no=${this.child.globalUserNo}`)
                        .then(() => {
                            this.$dialog.success({ message: 'settings.update-success' });
                            return true;
                        })
                        .catch(({ errorCode }) => {
                            this.$dialog.error(errorCode);
                            return false;
                        });

                    gaConfirmEvent('Reset', result);
                    await this.patchFamilyMember();
                },
            });
        },
    },
};
</script>

<style lang="less">
.family-member-dialog {
    .modal-dialog {
        width: 400px;
    }
    .modal-body {
        label {
            margin-bottom: 4px;
            font-size: 12px;
            text-transform: capitalize;
        }
        label + div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            > span {
                font-size: 15px;
                &:first-of-type {
                    color: black;
                    font-weight: bold;
                }
                button {
                    text-transform: uppercase;
                    color: #009bff;
                    &.red {
                        color: #d50022;
                    }
                }
                div + div {
                    margin-top: 4px;
                }
            }
        }
    }
    .modal-footer {
        padding-top: 0;
        #release-child {
            margin-bottom: 20px;
        }
    }
}
</style>
