export default {
    namespaced: true,
    state: {
        accountType: null,
        game: null,
        platform: null,
    },
    actions: {
        gaConsoleLinkingEvent({ state }, step, addition = {}) {
            if (!state.game || !state.platform) return;
            const data = {
                event: 'Console Linking',
                'consoleLinking - Step': step,
                'consoleLinking - Game': state.game, //the game they came from
                'consoleLinking - Platform': state.platform, //the console they are using  !!! 'steam', 'region-steam' are be blocked by GTM (Ethen)
                ...addition,
                ...(state.accountType ? { 'consoleLinking - Type': state.accountType } : {}),
            };
            window.dataLayer.push(data);
        },
    },
    mutations: {
        setGame(state, payload) {
            state.game = payload;
        },
        setPlatform(state, payload) {
            state.platform = payload;
        },
        setAccountType(state, payload) {
            state.accountType = payload;
        },
    },
};
