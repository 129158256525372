import router from '@/router';
export default {
    namespaced: true,
    state: {
        signUpSessionToken: null,
    },
    actions: {
        async checkAge({ rootGetters, dispatch }, { countryCode, dob, captchaVersion, captchaToken }) {
            // commit('setParams', { countryCode, dob });
            if (!captchaToken) {
                [captchaToken, captchaVersion] = await dispatch('recaptcha/getToken', 'signUpDob', { root: true });
            }

            const url = `/no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}signup/verify/age`;
            return await this._vm.$regionalAPI.post(url, {
                countryCode: countryCode,
                birthday: dob,
                deviceId: rootGetters.deviceId,
                captchaToken: captchaToken,
                captchaVersion: captchaVersion,
            });
        },
        async init({ rootState, dispatch, commit }, { isUnified, membershipNo = 0 }) {
            if (isUnified && !membershipNo && !rootState.country.ipMembershipNo) {
                await dispatch('country/getRegionInfo', '', { root: true });
            }
            const { signUpSessionToken } = isUnified
                ? await this._vm.$regionalAPI.post('/no-auth/unified/signup/init', {
                      membershipNo: membershipNo || rootState.country.ipMembershipNo,
                  })
                : await this._vm.$regionalAPI.post('/no-auth/signup/init');
            commit('setSignUpSessionToken', signUpSessionToken);
        },
        async signUp({ state, rootState }, signupParams) {
            if (router.currentRoute.query.parental_flow_mode === 'child')
                return await this._vm.$accountSettingAPI.post('/parental/child/signup', signupParams);
            if (rootState.partnerKey) {
                signupParams['partnerKey'] = rootState.partnerKey;
            }
            return await this._vm.$regionalAPI.post(
                `/no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}signup`,
                signupParams,
                {
                    headers: {
                        'X-Arena-Session-Token': state.signUpSessionToken,
                    },
                }
            );
        },
    },
    mutations: {
        setSignUpSessionToken(state, payload) {
            state.signUpSessionToken = payload;
        },
    },
};
