import router from '@/router';

export default {
    namespaced: true,
    state: {
        ipCountryCode: '',
        countryCodes: [],
        ipMembershipNo: 0,
        isVietnam: false,
        smsCountryPhoneCodes: [],
        selfServiceCountryPhoneCodes: [],
    },
    getters: {
        cmsRegionCode: (state) => {
            if (environment.region === 'sea' && state.isVietnam) {
                return 'vietnam';
            }
            return environment.region;
        },
        getIpMembershipNo: (state) => {
            return state.ipMembershipNo;
        },
    },
    actions: {
        async getCountryInfo({ commit, dispatch }) {
            if (environment.subRegion === 'ru') {
                commit('setCountryCodes', [{ code: 'RU' }]);
                commit('setIPCountryCode', 'RU');
                return;
            }
            await dispatch('getCountryCodes');
        },
        async getCountryCodes({ state, rootGetters, commit, dispatch }) {
            if (state.countryCodes.length > 0) return;

            const isUnified = router.currentRoute.query.unified || router.currentRoute.name.indexOf('unified') > -1;
            if (isUnified) await dispatch('getRegionInfo');

            const { data } = isUnified
                ? await this._vm.$countryAPI.get('no-auth/global-countries')
                : await this._vm.$countryAPI.get(`/no-auth/region-countries?membership_no=${rootGetters.membershipNo}`);

            commit('setCountryCodes', data.countryList);
            commit('setIPCountryCode', data.countryCode);
        },
        async checkRegionInfo({ dispatch }) {
            if (environment.region !== 'sea') return;
            await dispatch('getRegionInfo');
        },
        async getSmsCountryPhoneCodes({ rootGetters, commit }) {
            const { countryCodeList } = await this._vm.$accountAPI.get(
                `/no-auth/sms/country-code-list?membership_no=${rootGetters.membershipNo}`
            );
            commit('setSmsCountryPhoneCodes', countryCodeList);
        },
        async getSelfServiceCountryPhoneCodes({ rootGetters, commit }) {
            const { countryCodeList } = await this._vm.$selfServiceAPI.get(
                `/no-auth/mfa/sms/country-code-list?membership_no=${rootGetters.membershipNo}`
            );
            commit('setSelfServiceCountryPhoneCodes', countryCodeList);
        },
        async getRegionInfo({ state, commit }) {
            if (state.ipMembershipNo !== 0) return;
            const { data } = await this._vm.$countryAPI.get('no-auth/membership-no');
            commit('setRegionInfo', data);
        },
    },
    mutations: {
        setCountryCodes(state, payload) {
            state.countryCodes = payload;
        },
        setIPCountryCode(state, payload) {
            state.ipCountryCode = payload;
        },
        setRegionInfo(state, payload) {
            state.ipMembershipNo = payload.membershipNo;
            state.isVietnam = payload.isVietnam;
        },
        setSmsCountryPhoneCodes(state, payload) {
            state.smsCountryPhoneCodes = payload;
        },
        setSelfServiceCountryPhoneCodes(state, payload) {
            state.selfServiceCountryPhoneCodes = payload;
        },
    },
};
