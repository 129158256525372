import sha512 from 'crypto-js/sha512';
import router from '@/router';
import launcher from './launcher';
import migration from './migration';
import tpa from './tpa';
import authTypes from './auth-types';
import global from './global';

export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
        loginId: '',
        password: '',
        hashedPassword: '',
        isTPA: false,
        rememberMe: false,
        redirect: true, // flag for default redirection after login
        autoLoginWeb: false,
        countryCode: '',
    },
    actions: {
        async emailCheck({ rootGetters }, { id }) {
            await this._vm.$regionalAPI.post(
                `no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}login/validate`,
                {
                    id,
                    deviceId: rootGetters.deviceId,
                }
            );
        },

        async checkLogin({ rootState, rootGetters, commit }) {
            let isLogin = false;
            try {
                if (rootState.isAppMode) {
                    await this._vm.$accountAPI.get(`/no-auth/login/status?_t=${Date.now()}`);
                } else {
                    await this._vm.$regionalAPI.get(
                        `/no-auth/${
                            router.currentRoute.query.unified ? 'unified/' : ''
                        }web/login/status?_t=${Date.now()}&device_id=${rootGetters.deviceId}`
                    );
                }
                isLogin = true;
                // eslint-disable-next-line no-empty
            } catch {}
            commit('setIsLoggedIn', isLogin);
            return isLogin;
        },

        async login({ rootState, commit, dispatch }, { id, password, redirect = true } = {}) {
            if (id) commit('setId', id);
            if (password) commit('setPassword', password);
            commit('setRedirect', redirect);
            commit('setTpaClicked', '', { root: true }); //reset tpaClicked (in order to push dataLayer 'Account Type': 'Nexon' or {tpaName} for loginSuccess event) for normal login web/launcher since tpaClicked is stored in localstorage and need to be reset if it's normal login
            try {
                if (router.currentRoute.query.parental_flow_mode === 'child') {
                    await dispatch('loginChild');
                } else if (rootState.isApplication) {
                    await dispatch('launcher/login');
                } else {
                    await dispatch('loginWeb');
                }
            } catch (err) {
                await dispatch('loginErrorHandle', err);
            }
        },
        async loginWeb({ state, rootGetters, dispatch }) {
            const [captchaToken, captchaVersion] = await dispatch('recaptcha/getToken', 'web_login', { root: true });
            const data = await this._vm.$regionalAPI.post(
                `/no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}web/email/login`,
                {
                    id: state.loginId,
                    password: state.password,
                    captchaToken,
                    captchaVersion,
                    deviceId: rootGetters.deviceId,
                    localTime: Date.now(),
                    timeOffset: new Date().getTimezoneOffset(),
                    autoLogin: state.autoLoginWeb,
                }
            );

            // update GNB login status
            window.Gnb.$emit('sessionUpdate');

            await dispatch('afterLoginCommon', { ...data, autoLogin: state.autoLoginWeb });
        },
        // parental controls - child login along with parent login session
        async loginChild({ state, dispatch }) {
            const [captchaToken, captchaVersion] = await dispatch('recaptcha/getToken', 'web_login', { root: true });
            await this._vm.$accountSettingAPI.post('/parental/child/login', {
                id: state.loginId,
                password: state.password,
                captchaToken,
                captchaVersion,
            });

            router.replace({ name: 'add-child-complete' });
        },
        async healup({ dispatch }, params) {
            await this._vm.$accountAPI.put(
                `/no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}healup`,
                params
            );

            await dispatch('silentLogin');
        },

        // not only healup, but also sms required flag feature (TW region) are using healup session which requires silent login api call
        async silentLogin({ rootState, rootGetters, state, dispatch }) {
            if (rootState.isApplication) {
                return await dispatch('launcher/silentLogin', state.isTPA);
            }

            if (state.isTPA) {
                return await dispatch('tpa/loginWeb');
            }

            const data = await this._vm.$accountAPI.post(
                `/no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}login/silent`,
                {
                    deviceId: rootGetters.deviceId,
                    localTime: Date.now(),
                    timeOffset: new Date().getTimezoneOffset(),
                }
            );
            await dispatch('afterLoginCommon', data);
        },

        async verify({ rootGetters, state, commit, dispatch }, { code, authType, rememberMe }) {
            await this._vm.$accountAPI.put(
                `/no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}trusted-device/verify`,
                {
                    email: state.loginId,
                    deviceId: rootGetters.deviceId,
                    verificationCode: code,
                    authType,
                    rememberMe: false, // always false due to UI flow (we need to ask device name, so we cannot add it right away now)
                }
            );

            commit('setRememberMe', rememberMe);

            // only first tpa link requires verification
            if (state.isTPA) {
                await dispatch('tpa/signUpTpa');
            } else {
                await dispatch('login');
            }
        },
        // it will be called after web (tpa) or launcher (tpa) login api
        async afterLoginCommon({ state, rootState, dispatch, commit }, data) {
            commit('setId', ''); // clean up
            commit('setPassword', ''); // clean up
            commit('setCountryCode', data.countryCode);
            if (rootState.isApplication) {
                commit('launcher/setData', data);
            }
            dataLayer.push({
                event: 'loginSuccess',
                userId: data.hashedUserNo,
                globalUserId: data.hashedGlobalUserNo,
                'Account Type': rootState.tpaClicked ? rootState.tpaClicked : 'Nexon',
                ...(Object.prototype.hasOwnProperty.call(data, 'autoLogin') // added 'Auto Log In Checkbox' only for web for now
                    ? { 'Auto Log In Checkbox': data.autoLogin ? 'Checked' : 'Not Checked' }
                    : {}),
            });

            this._vm.$cookie.set('NexonUserID', data.hashedUserNo);
            this._vm.$cookie.set('NxGUN', data.hashedGlobalUserNo);

            // after login verification api and login api call
            if (state.rememberMe) {
                await dispatch('settings/trustedDevice/addDevice', 'rememberMe', { root: true });
            }

            if (rootState.isApplication) {
                return await dispatch('launcher/afterLogin');
            }

            // below from here is only for web
            const prodId = this._vm.$cookie.get('ProdId');
            if (prodId && prodId === '50203' && router.currentRoute.query && router.currentRoute.query.betasignup) {
                await dispatch('settings/refreshAccountData', null, { root: true });
                // check if it's kartrider product id and if there is betasignup query params
                // redirect kartrider users after signup to the return url without displaying successfull verified page
                dispatch('defaultRedirectHandler', null, { root: true });
                return;
            }

            if (rootState.signupParams) {
                // after signup verify flow, it needs to show up signup success page instead of promo 2fa
                router.replace({ name: 'create-account-complete' });
                return;
            }

            window.Gnb.$emit('sessionUpdate');

            // parent flow
            if (router.currentRoute.query.parental_flow_mode === 'parent') {
                return router.replace({ name: 'parental-controls.start' });
            }

            if (router.currentRoute.params.isSetupPassword) {
                await this._vm.$dialog.success({
                    title: 'login.all-set.title',
                    message: 'login.all-set.body',
                });
                dispatch('defaultRedirectHandler', null, { root: true });
            }

            // this is for promo 2fa
            // not need to show this right after verification (guess)
            if (!state.rememberMe) {
                await dispatch('settings/refreshAccountData', null, { root: true });
                if (rootState.settings.emailVerified && !rootState.settings.skipMfaPromo) {
                    router.replace({
                        name: 'promo-2fa',
                    });
                    return;
                }
            }

            if (state.redirect) {
                dispatch('defaultRedirectHandler', null, { root: true });
            }
        },
        async loginErrorHandle({ commit, state, rootGetters, dispatch }, err) {
            if (router.history.pending) return; // handled by global api intercepter
            if (err.errorCode) {
                if (err.errorCode === 20184) {
                    const { code, uid } = JSON.parse(err.message);
                    this._vm.$dialog.legacySupport({ csCode: code, uid });
                    return;
                } else if (err.errorCode === 20211) {
                    const { email } = JSON.parse(err.message);
                    const confirm = await this._vm.$dialog.legacyEmail({ email });
                    if (confirm) {
                        commit('setId', email);
                        try {
                            await this._vm.$regionalAPI.post('no-auth/login/migrate-legacy', {
                                id: state.loginId,
                                password: state.password,
                                email,
                                deviceId: rootGetters.deviceId,
                            });
                            dispatch('login', { email });
                        } catch (migrateErr) {
                            migrateErr.email = email;
                            throw migrateErr;
                        }
                    }
                    return;
                }
            }
            throw err;
        },
    },
    mutations: {
        setIsLoggedIn(state, value) {
            state.isLoggedIn = value;
        },
        setId(state, value) {
            state.loginId = value;
        },
        setPassword(state, value) {
            state.password = value;
            state.hashedPassword = sha512(value).toString();
        },
        setIsTPA(state, value) {
            state.isTPA = value;
        },
        setRememberMe(state, value) {
            state.rememberMe = value;
        },
        setRedirect(state, value) {
            state.redirect = value;
        },
        setAutoLoginWeb(state, value) {
            state.autoLoginWeb = value;
        },
        setCountryCode(state, value) {
            state.countryCode = value;
        },
    },
    modules: {
        tpa,
        migration,
        launcher,
        authTypes,
        global,
    },
};
