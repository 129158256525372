import trustedDevice from './trusted-device';
import parentalControls from './parental-controls';

export default {
    namespaced: true,
    state: {
        /// belows are from /api/account/{version}/account api
        primaryAuthType: '',
        authTypeList: [],
        countryCode: '',
        email: '',
        enableMail: false,
        phoneNumberResetEnabled: false, // enableUserPhoneReset
        hasPassword: false, // hasSetPassword
        emailVerified: false, // isVerified
        fullName: '', // name
        nexonTag: '',
        phoneNumber: '', // phoneNo
        renamesRemaining: 0,
        searchable: false,
        skipMfaPromo: true,
        tpaAccountList: [],
        trustedDeviceCount: 0,
        twitchUserId: '',
        steamName: '',
        isMinor: false,
        ///

        formErrorCode: '',
    },
    getters: {
        authType(state) {
            return state.primaryAuthType || state.authTypeList[0] || 'EMAIL';
        },
    },
    actions: {
        async refreshAccountData({ commit }) {
            if (environment.region === 'one') return;
            const data = await this._vm.$accountAPI.get('/account');
            commit('setAccountData', data);
        },
    },
    mutations: {
        setFormError(state, payload) {
            state.formErrorCode = payload;
        },
        setAccountData(state, payload) {
            for (let property in payload) {
                const value = payload[property];
                if (property === 'isVerified') property = 'emailVerified';
                else if (property === 'phoneNo') property = 'phoneNumber';
                else if (property === 'name') property = 'fullName';
                else if (property === 'hasSetPassword') property = 'hasPassword';
                else if (property === 'enableUserPhoneReset') property = 'phoneNumberResetEnabled';
                if (!Object.prototype.hasOwnProperty.call(state, property)) continue;
                state[property] = value;
            }
        },
    },
    modules: {
        trustedDevice,
        parentalControls,
    },
};
