<template>
    <div class="message-box dialog-mask legacy-support">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('login.legacy.missing-email.title') }}</h4>
                </div>
                <div class="modal-body">
                    <!-- TODO: v-html can lead to XSS attacks and should be avoided  -->
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p
                        v-html="
                            $t('login.legacy.missing-email.message', {
                                csEmail: `<a style='color:rgb(2,167,240);font-weight:bold;cursor:pointer;' target='_blank' rel='external' href='mailto:${csEmail}?subject=${csSubject}&body=${csBody}'>${csEmail}</a>`,
                            })
                        "
                    ></p>
                    <div class="cs-code">
                        <span>{{ $t('login.legacy.missing-email.code') }}</span>
                        <span class="code">{{ csCode }}</span>
                        <div class="copy-wrapper">
                            <span class="copy" :class="{ copied: copied }" @click="copyCode">{{
                                $t(`login.legacy.missing-email.copy`)
                            }}</span>
                            <span class="copy" :class="{ copied: !copied }" @click="copyCode">{{
                                $t(`login.legacy.missing-email.copied`)
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-xs-6 pull-right">
                        <FormButton
                            id="modalPrimaryButtonId"
                            data-cy="error-modal-close"
                            class="btn-black"
                            :button-click="closeModal"
                            :label="$t('close')"
                        >
                        </FormButton>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FormButton } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    name: 'ModalLegacySupport',
    components: {
        FormButton,
    },
    mixins: [modalEscMixin],
    props: {
        csCode: {
            type: Number,
            default: 0,
        },
        uid: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            loading: false,
            copied: false,
            csEmail: 'na_login_issues@nexon.com',
            csSubject: 'Support - Email Required Account',
        };
    },
    computed: {
        csBody() {
            return `UID: ${this.uid}%0D%0ACode: ${this.csCode}`;
        },
    },
    created() {
        this.handleEscCallbackToMixin = this.closeModal;
    },
    methods: {
        closeModal() {
            this.$close(true);
        },
        copyCode() {
            dataLayer.push({
                event: 'SignupLoginErrorClick',
                errorName: 'Legacy Email Required',
                errorClick: 'Copy the Code',
            });
            if (!navigator.clipboard) {
                this.fallbackCopyClipboard();
                return;
            }
            navigator.clipboard.writeText(this.csCode).then(
                () => {
                    this.copied = true;
                },
                (error) => {
                    console.log(error);
                    this.copied = false;
                }
            );
        },
        fallbackCopyClipboard() {
            const textArea = document.createElement('textarea');
            textArea.value = this.csCode;

            // Avoid scrolling to bottom
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.position = 'fixed';

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                const success = document.execCommand('copy');
                this.copied = success ? true : false;
            } catch {
                this.copied = false;
            }

            document.body.removeChild(textArea);
        },
    },
};
</script>

<style lang="less" scoped>
.legacy-support {
    .cs-code {
        display: flex;
        margin-top: 20px;
        span:first-child {
            margin-right: 5px;
        }
        .code {
            font-weight: bold;
            margin-right: 10px;
        }
        .copy-wrapper {
            display: inline-block;
            span.copy {
                cursor: pointer;
                opacity: 1;
                position: absolute;
                text-decoration: underline;
                transition: opacity 0.5s linear;
            }
            .copied {
                opacity: 0 !important;
            }
        }
    }
}
</style>
