export default {
    bind(el, binding) {
        const { bubble } = binding.modifiers,
            handler = (e) => {
                if (bubble || (!el.contains(e.target) && el !== e.target)) {
                    binding.value(e);
                }
            };

        el.vueClickOutside = handler;
        document.addEventListener('click', handler);
    },
    unbind(el) {
        document.removeEventListener('click', el.vueClickOutside);
        el.vueClickOutside = null;
    },
};
