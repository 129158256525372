<template>
    <div class="message-box dialog-mask trusted-device-dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('trusted-device-modal.remove-title') }}</h4>
                </div>
                <div class="modal-body">
                    <p class="modal-desc">
                        {{ $t('trusted-device-modal.remove-description') }}
                    </p>
                    <div class="device-name-container">
                        <label for="device-name">{{ $t('trusted-device-modal.input-label') }}</label>
                        <span id="device-name" class="device-name">{{ this.arguments[0] }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :btn-primary-text="$t('trusted-device-modal.remove')"
                        :btn-secondary-text="$t('cancel')"
                        btn-primary-id="remove-device"
                        btn-secondary-id="cancel"
                        :primary-button-click="() => close(true)"
                        :secondary-button-click="() => close(false)"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModalMultiButtons } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        ModalMultiButtons,
    },
    mixins: [modalEscMixin],
    props: {
        arguments: {
            type: Array,
            default: () => [],
        },
    },
    created() {
        this.handleEscCallbackToMixin = () => this.close(false);
    },
    methods: {
        close(rememberDevice) {
            this.$close(rememberDevice);
        },
    },
};
</script>

<style lang="less" scoped>
.trusted-device-dialog {
    .modal-body {
        .modal-desc {
            margin-bottom: 10px;
        }

        .device-name-container {
            margin-top: 40px;
            .device-name {
                margin-left: 20px;
                font-weight: bold;
                color: #000;
            }
        }
    }
}
</style>
