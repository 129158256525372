import { loadView } from './_utils';
import store from '@/store';

const unifiedRoutes = [
    {
        path: 'unified',
        component: {
            render(c) {
                return c('router-view');
            },
        },
        meta: { coQuery: ['simple_signup'] },
        children: [
            {
                path: '',
                redirect: { name: 'unified-login' },
            },
            {
                path: 'login',
                name: 'unified-login',
                component: loadView('login'),
                beforeEnter(_to, _from, next) {
                    if (store.state.channel === 'steam') {
                        // steam connector shouldn't show login page
                        store.dispatch('login/launcher/launcherLoginCancel');
                    } else {
                        next();
                    }
                },
                props: { isUnified: true },
            },
            {
                path: 'create',
                name: 'unified-signup',
                meta: { requiresNotLogin: !store.state.isApplication },
                component: loadView('create/create-account'),
                props: { isUnified: true },
            },
        ],
    },
];

export default unifiedRoutes;
