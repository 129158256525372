export default {
    namespaced: true,
    state: {
        legacyEmail: '',
        legacyID: '',
        emailVerified: false,
    },
    actions: {
        migrate({ state }, { id, password }) {
            return this._vm.$accountAPI.post('/no-auth/migrate', {
                id: state.legacyID,
                password,
                newId: id,
                refId: state.legacyEmail,
            });
        },
    },
    mutations: {
        setState(state, { legacyEmail, legacyID, emailVerified }) {
            state.legacyEmail = legacyEmail;
            state.legacyID = legacyID;
            state.emailVerified = emailVerified;
        },
    },
};
