import whitelist from '@/config/whitelist-domains';
import store from '@/store';

export const sanitizeUrl = (redirectURL, altUrl = '/account/setting') => {
    if (redirectURL) {
        const urlO = new URL(redirectURL, document.baseURI),
            redirectDomain = urlO.hostname.toLowerCase();
        try {
            const match = whitelist.some((one) => redirectDomain.endsWith(one));
            if (!match) {
                throw `redirect url (${redirectURL}) has been filtered out`;
            }
            return redirectURL;
        } catch {}
    }
    return altUrl;
};

export const getUnifiedRedirectUrl = async ({ route = '/main', membershipNo, countryCode }) => {
    if (!membershipNo && !countryCode) return route;

    if (countryCode) {
        await store.dispatch('country/getCountryCodes');
        const country = store.state.country.countryCodes.find((x) => x.code === countryCode);
        membershipNo = country.membershipNo;
    }

    if (membershipNo === 6 && route === '/main') route = '/account/setting';

    return `${replaceUnifiedSubdomain(membershipNo)}${route}`;
};

const replaceUnifiedSubdomain = (membershipNo) => {
    if (!membershipNo) return location.origin;
    return location.origin.replace(/[^.//-\s]+\.nexon\.com/g, `${getRegionalDomain(membershipNo)}.nexon.com`);
};

export const getRegionalDomain = (membershipNo) => {
    switch (membershipNo) {
        case 1:
            return 'www';
        case 3:
            return 'th';
        case 4:
            return 'tw';
        case 5:
            return 'sea';
        case 6:
            return 'japan';
        default:
            return 'www';
    }
};

export default async (redirectUrl) => {
    let url = sanitizeUrl(redirectUrl);
    if (typeof url === 'string' && environment.region === 'one' && url.indexOf('account/setting') > -1) {
        url = await getUnifiedRedirectUrl({ countryCode: store.state.login.countryCode });
    }
    window.location.href = url;
};
