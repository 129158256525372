import router from '@/router';
import store from '@/store';

const getAuthType = () => {
    const fallback = 'email';
    try {
        let value = store.state.settings.primaryAuthType;
        if (!value) {
            value = store.state.settings.authTypeList.length > 0 ? store.state.settings.authTypeList[0] : fallback;
        }
        return value.toLowerCase();
    } catch {
        return fallback;
    }
};

const navToVerify = (name, verificationParams) => {
    store.commit('setVerificationParams', verificationParams);
    router.push({
        name,
    });
};

export const requestEmail = (name, verificationParams) => {
    store.commit('settings/setFormError', '');

    return router.app.$accountAPI
        .post(verificationParams.emailRequest.route, verificationParams.emailRequest.params)
        .then((response) => {
            if (response && response.isBypassed) {
                // bypass change and create password when isBypassed from the POST /api/account/v1/reset/request-reset response is true (FE-2136)
                router.replace({
                    name: verificationParams.routeTo,
                });
            } else {
                navToVerify(name, verificationParams);
            }
        });
};

export const requestSms = (name, verificationParams) => {
    store.commit('settings/setFormError', '');
    return router.app.$accountAPI
        .post(`/no-auth/${router.currentRoute.query.unified ? 'unified/' : ''}sms/request-code`)
        .then(() => {
            navToVerify(name, verificationParams);
        });
};

const errorHandler = ({ errorCode }) => {
    store.commit('settings/setFormError', errorCode);
};

const verifyAccount = (verificationParams) => {
    const authType = getAuthType();
    let name = 'verify-account';
    switch (authType) {
        case 'totp':
            name = 'verify-code-2fa-settings';
            navToVerify(name, verificationParams);
            break;
        case 'sms':
            name = 'verify-code-sms-settings';
            requestSms(name, verificationParams).catch(() => {
                navToVerify('auth-types-2fa-settings', verificationParams);
            });
            break;
        default:
            requestEmail(name, verificationParams).catch(errorHandler);
            break;
    }
};

export default verifyAccount;
