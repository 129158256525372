export function hasFromOPENAPI_WEBSITE() {
    try {
        const currentUrl = new URLSearchParams(location.search);
        const returnUrlEncoded = currentUrl.get('return_url');
        const returnUrl = decodeURIComponent(returnUrlEncoded);
        const returnUrlParams = new URLSearchParams(`?${returnUrl.split('?')[1] || ''}`);
        const redirectInsignUri = returnUrlParams.get('redirect_uri');
        const redirectDecodedUri = window.atob(redirectInsignUri);
        const hasFromOpenAPIWebsite = /openapi.nexon.com/.test(redirectDecodedUri);
        return hasFromOpenAPIWebsite;
    } catch (e) {
        console.warn(e);
        return false;
    }
}
