import { loadView } from './_utils';
import parentalControlsRoutes from './parental-controls';

export default [
    {
        path: 'setting',
        component: loadView('setting'),
        meta: { requireSettingsInfo: true },
        children: [
            {
                path: '',
                name: 'setting',
                redirect: { name: 'setting.account-overview' },
            },
            {
                path: 'account-overview',
                name: 'setting.account-overview',
                component: loadView('setting/overview'),
            },
            {
                path: 'personal-info',
                name: 'setting.personal-info',
                component: loadView('setting/personal-info'),
            },
            {
                path: 'security',
                name: 'setting.security',
                component: loadView('setting/security'),
            },
            {
                path: 'linked-account',
                name: 'setting.linked-account',
                component: loadView('setting/linked-account'),
            },
            {
                path: 'communication-preference',
                name: 'setting.communication-preference',
                component: loadView('setting/communication-preference'),
            },
            {
                path: 'parental-controls',
                name: 'setting.parental-controls',
                component: loadView('setting/parental-controls'),
            },
        ],
    },
    {
        path: 'setting/verify-account',
        name: 'verify-account',
        component: loadView('setting/verify-account'),
        meta: { requireSettingsInfo: true },
    },
    {
        path: 'setting/password/:type?',
        name: 'create-change-password',
        component: loadView('setting/create-change-password'),
        meta: { requireSettingsInfo: true },
    },
    {
        path: 'setting/change-email',
        name: 'change-email',
        component: loadView('setting/change-email'),
        meta: { requiresLogin: true },
    },
    {
        path: 'setting/change-name',
        name: 'change-name',
        component: loadView('setting/change-name'),
        meta: { requireSettingsInfo: true },
    },
    {
        path: 'setting/manage-payment-methods',
        name: 'manage-payment-methods',
        component: loadView('setting/manage-payment-methods'),
        meta: { requireSettingsInfo: true },
    },
    {
        path: 'setting/manage-trusted-devices',
        name: 'manage-trusted-devices',
        component: loadView('setting/manage-trusted-devices'),
        meta: { requireSettingsInfo: true },
    },
    {
        path: 'promo-2fa',
        name: 'promo-2fa',
        component: loadView('setting/promo-2fa'),
        meta: { requireSettingsInfo: true },
    },
    {
        path: 'settings/social-link/:socialProvider',
        name: 'setting-social-link',
        component: loadView('setting/social-link'),
        props: true,
    },
    {
        path: 'revert-id',
        name: 'revert-id',
        component: loadView('revert-id'),
    },
    {
        path: 'revert-id-success',
        name: 'revert-id-success',
        component: loadView('revert-id-success'),
    },
    {
        path: 'authenticator-enable',
        name: 'authenticator-enable',
        component: loadView('setting/authenticator-enable'),
        meta: { requireSettingsInfo: true },
    },
    {
        path: 'verify-code',
        name: 'verify-code-2fa-settings',
        component: loadView('setting/verify-code-2fa'),
        meta: { requiresLogin: true },
    },
    {
        path: 'verify-backup-code',
        name: 'verify-backup-code-2fa-settings',
        component: loadView('setting/verify-code-2fa'),
        meta: { requiresLogin: true },
    },
    {
        path: 'auth-types',
        name: 'auth-types-2fa-settings',
        component: loadView('setting/auth-types-2fa'),
        meta: { requireSettingsInfo: true },
    },
    {
        path: 'verify-sms',
        name: 'verify-code-sms-settings',
        component: loadView('setting/verify-code-sms'),
        meta: { requiresLogin: true },
    },
    ...parentalControlsRoutes,
];
