import VueCookie from 'vue-cookie';
import accountAPI from '@/plugins/api/global-account';
import router from '@/router';
import store from '@/store';

const isLauncher = () => navigator.userAgent.indexOf('NexonLauncher') > 0;

const isSteamConnector = () => navigator.userAgent.indexOf('steam-connector') > 0;

const isAppMode = () => isLauncher() || isSteamConnector();

const getPlatform = () => router.currentRoute.params.platform;

// ssoRequired = true -> launcher will open up /account/login/sso?redirect_url={url} as wrapper page in order to carryover launcher session
/// url must not be external website along with ssoRequired = true because above redirect_url cannot be external due to security reason
const openExternal = (url, ssoRequired = false) => {
    if (!isAppMode()) return;
    const open = window.openExternal || window.parent.openExternal || window.parent.electron.openExternal;
    open(url, ssoRequired);
};

const launcherLogin = async (data) => {
    if (!isAppMode()) return false;
    const prodId = VueCookie.get('ProdId');
    if (prodId) data.productId = prodId;
    try {
        if (window.launcherLogin) return window.launcherLogin(data);
        return window.parent.launcherLogin(data);
    } catch (e) {
        return false;
    }
};

const launcherSignupCompleted = (data) => {
    if (!isAppMode()) return false;
    const prodId = VueCookie.get('ProdId');
    if (prodId) data.product_id = prodId;
    try {
        if (window.launcherSignupCompleted) return window.launcherSignupCompleted(data);
        return window.parent.launcherSignupCompleted(data);
    } catch (e) {
        return false;
    }
};

const launcherLanguageChange = (locale) => {
    locale = locale.replace('-', '_');
    if (window.launcherLanguageChange) return window.launcherLanguageChange(locale);
    return window.parent.launcherLanguageChange(locale);
};

const launcherEvent = (topic, data) => {
    if (!window.requestToMain) return false;
    return window.requestToMain(topic, data);
};

const steamSettings = window.settings || window.parent.settings;

const steamConnector = {
    login(accountId) {
        return accountAPI
            .post(`/no-auth/global/${getPlatform()}/login/connector`, {
                clientId: steamSettings.client_id, // steam connector
                deviceId: steamSettings.device_id, // steam connector
            })
            .then((data) => {
                dataLayer.push(
                    { event: 'formSubmit', formName: router.currentRoute.name },
                    {
                        event: 'loginSuccess',
                        userId: data.hashedUserNo,
                        globalUserId: data.hashedGlobalUserNo,
                        'Account Type': store.state.tpaClicked,
                    },
                    {
                        event: 'steamGameLaunch',
                        productId: steamSettings.appID,
                        userId: data.hashedUserNo,
                        globalUserId: data.hashedGlobalUserNo,
                    }
                );
                if ([50203, 50210].includes(steamSettings.appID)) {
                    dataLayer.push({
                        event: 'KRD Global Account Link',
                        userId: data.hashedUserNo,
                        globalUserId: data.hashedGlobalUserNo,
                        channel: getPlatform(),
                    });
                }
                if (steamSettings.version && steamSettings.version >= '1.7.0') {
                    launcherLogin(data);
                } else {
                    // temporary for legacy steam connector
                    return accountAPI
                        .post('/auth-token', {
                            deviceId: steamSettings.device_id, // steam connector
                        })
                        .then((data2) => {
                            launcherLogin({
                                ...data,
                                ...data2,
                                login_id: accountId,
                            });
                        });
                }
            });
    },
};

export default {
    isLauncher,
    isSteamConnector,
    isAppMode,
    launcherLogin,
    launcherSignupCompleted,
    launcherLanguageChange,
    launcherEvent,
    steamConnector,
    openExternal,
};
