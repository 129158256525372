import Vue from 'vue';
import Vuex from 'vuex';
import { uuid } from 'vue-uuid';
import accountAPI from '@/plugins/api/account';
import persistedState from 'vuex-persistedstate';
import login from './login';
import create from './create';
import consoleTracking from './console-tracking';
import recaptcha from './recaptcha';
import country from './country';
import settings from './settings';
import router from '@/router';
import safeRedirect from '@/plugins/safe-redirect';
import selfService from './self-service';
import newsletter from './newsletter';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        persistedState({
            paths: ['verifiedTokenEmail', 'consoleTracking', 'tpaClicked', 'verificationParams'],
        }),
    ],
    state: {
        region: environment.region,
        isMobile: /(iPhone|iPad|Android|Mobile)/gi.test(navigator.userAgent),
        isIE: /(MSIE|Trident)/gi.test(navigator.userAgent),
        isLauncher: /(NexonLauncher)/gi.test(navigator.userAgent),
        isApplication: /(NexonLauncher|steam-connector)/gi.test(navigator.userAgent),
        isPlayStation: /(PlayStation)/gi.test(navigator.userAgent),
        configurations: {},
        thirdPartyList: [],
        gameList: [],
        migration: {
            accountEmail: null,
            legacyLoginID: null,
            emailVerified: false,
        },
        challengeTitle: null,
        authorizeParams: {},
        productId: null,
        partnerKey: null,
        middleWareUrl: null,
        optOutInfo: null,
        directReturn: null,
        channel: null,
        createdAccountEmail: null,
        verifiedTokenEmail: null,
        appType: 'web',
        loading: false,
        landing: false,
        tpaClicked: '',
        verificationParams: {},
        signupParams: null,
        lang: 'en',
    },
    getters: {
        membershipNo(state) {
            switch (state.region) {
                case 'global':
                    return 1;
                case 'th':
                    return 3;
                case 'tw':
                    return 4;
                case 'sea':
                    return 5;
                case 'japan':
                    return 6;
            }
        },
        deviceId(state) {
            if (state.isApplication) {
                const settings = window.settings || window.parent.settings;
                if (settings && settings.device_id) return settings.device_id; // steam connector
                if (window.parent.application && window.parent.application.params)
                    return window.parent.application.params.device_id; // launcher
            }
            let deviceId = localStorage.getItem('device_id');
            if (!deviceId) {
                deviceId = uuid.v4();
                localStorage.setItem('device_id', deviceId);
            }
            return deviceId;
        },
        language(state) {
            const locale = state.lang;
            if (locale === 'es') return 'es_419';
            if (locale === 'en') return 'en_US';
            if (locale === 'pt') return 'pt_BR';
            if (locale === 'zh') return 'zh_TW';
            return `${locale}_${locale.toUpperCase()}`;
        },
    },
    actions: {
        retrieveConfiguration({ state, commit }) {
            accountAPI
                .get(`/no-auth/${state.region === 'one' ? 'unified/' : ''}configuration`)
                .then((data) => {
                    commit('setConfiguration', data);
                })
                .catch(() => {});
        },
        // eslint-disable-next-line no-empty-pattern
        defaultRedirectHandler({}, queryParam) {
            const query = queryParam || router.currentRoute.query;
            if (query.route_to) {
                router.replace({ name: query.route_to }); // internal
            } else {
                safeRedirect(query.return_url || query.redirect_uri); // external in priority order
            }
        },
        async getGameList({ rootGetters, dispatch, commit, state }) {
            if (state.gameList.length > 0) return state.gameList;
            await dispatch('country/checkRegionInfo', '', { root: true });
            const { data } = await this._vm.$cdnAPI.getGameList(rootGetters['country/cmsRegionCode']);
            commit('setGameList', data);
        },
    },
    mutations: {
        setAppType(state, payload) {
            state.appType = payload;
        },
        setLandingPage(state, payload) {
            state.landing = payload;
        },
        setVerifiedTokenEmail(state, payload) {
            state.verifiedTokenEmail = payload;
        },
        setGameList(state, payload) {
            state.gameList = payload;
        },
        clearVerifiedTokenEmail(state) {
            state.verifiedTokenEmail = null;
        },
        setDirectReturn(state, payload) {
            state.directReturn = payload;
        },
        getOptOutInfo(state, payload) {
            state.optOutInfo = payload;
        },
        getMiddlewareUrl(state, payload) {
            state.middleWareUrl = payload;
        },
        setProductId(state, payload) {
            state.productId = payload;
        },
        setPartnerKey(state, payload) {
            state.partnerKey = payload;
        },
        getAuthorizeParams(state, payload) {
            state.authorizeParams = { ...payload };
        },
        setChallengeTitle(state, payload) {
            state.challengeTitle = payload;
        },
        setConfiguration(state, payload) {
            state.configurations = { ...payload };
        },
        setMigrationData(state, payload) {
            state.migration = Object.assign({}, state.migration, payload);
        },
        setLegacyLoginID(state, payload) {
            state.legacyLoginID = payload;
        },
        clearValidationError(state, payload) {
            Vue.delete(state.inlineInput.errors, payload.field);
        },
        getThirdPartyList(state, payload) {
            state.thirdPartyList = [...payload];
        },
        setChannel(state, payload) {
            state.channel = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setTpaClicked(state, payload) {
            state.tpaClicked = payload;
        },
        setVerificationParams(state, payload) {
            state.verificationParams = payload;
        },
        clearVerificationParams(state) {
            state.verificationParams = {};
        },
        setLang(state, lang) {
            state.lang = lang;
        },
        setSignupParams(state, payload) {
            state.signupParams = payload;
        },
        setDeviceId(state, payload) {
            state.deviceId = payload;
        },
    },
    modules: {
        login,
        create,
        consoleTracking,
        recaptcha,
        country,
        settings,
        selfService,
        newsletter,
    },
});
