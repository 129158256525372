// import verifyAccount from '@/plugins/verify-account';
import router from '@/router';

export default {
    namespaced: true,
    state: {
        list: [],
    },
    actions: {
        async getList({ commit }) {
            const data = await this._vm.$accountAPI.get('/trusted-device');
            commit('setList', data);
        },
        async renameDevice({ dispatch }, { deviceName, deviceId }) {
            const result = await this._vm.$dialog.trustedDeviceEditName(deviceName);
            if (!result.rememberDevice || result.deviceName === deviceName) return;
            await this._vm.$accountAPI.put('/trusted-device/name', {
                deviceId,
                name: result.deviceName,
            });
            return result.deviceName;
        },
        async addDevice({ rootGetters, dispatch }, type = '') {
            const result = await this._vm.$dialog.trustedDevice({ type }); // type = 'rememberMe' for login verification rememberMe
            if (!result.rememberDevice) return;
            const isUnified = router.currentRoute.query.unified || router.currentRoute.name.indexOf('unified') > -1;
            await this._vm.$accountAPI.put(`/${isUnified ? 'unified/' : ''}trusted-device`, {
                name: result.deviceName,
                deviceId: rootGetters.deviceId,
            });
            return result.deviceName;
        },
        async removeDevice({ rootState, dispatch }, deviceId) {
            await this._vm.$accountAPI.put('/trusted-device/reset', {
                deviceId,
                language: rootState.language,
                localTime: Date.now(),
                timeOffset: new Date().getTimezoneOffset(),
            });
        },
        async removeAllDevices({ rootState, dispatch }) {
            await this._vm.$accountAPI.put('/trusted-device/reset/all', {
                language: rootState.language,
                localTime: Date.now(),
                timeOffset: new Date().getTimezoneOffset(),
            });
        },
    },
    mutations: {
        setList(state, list) {
            state.list = list.map((x) => {
                if (x.type === 'UNDEFINED') x.type = '';
                return x;
            });
        },
    },
};
