<template>
    <div class="message-box dialog-mask">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('settings.enable-sms.title') }}</h4>
                </div>
                <div class="modal-body">
                    <p>{{ $t('settings.enable-sms.desc-1') }}</p>
                    <p>
                        {{ $t('settings.enable-sms.desc-2') }} <strong>{{ phoneNumber }}</strong
                        ><span v-if="phoneNumberResetEnabled" class="change-sms" @click="clickChange">{{
                            $t('settings.enable-sms.change')
                        }}</span>
                    </p>
                    <p>{{ $t('settings.enable-sms.desc-3') }}</p>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :loading="loading"
                        data-cy="buttons"
                        btn-primary-id="confirm"
                        :btn-primary-text="$t('settings.enable')"
                        :btn-secondary-text="$t('cancel')"
                        :primary-button-click="clickYes"
                        :secondary-button-click="clickNo"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ModalMultiButtons } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        ModalMultiButtons,
    },
    mixins: [modalEscMixin],
    props: {
        confirmResult: {
            type: Boolean,
            default: true,
        },
        confirmFunction: {
            type: Function,
            default: null,
        },
        changeFunction: {
            type: Function,
            default: null,
        },
        cancelResult: {
            type: Boolean,
            default: false,
        },
        phoneNumber: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapState('settings', ['phoneNumberResetEnabled']),
    },
    created() {
        this.handleEscCallbackToMixin = this.clickNo;
    },
    methods: {
        async clickYes() {
            try {
                if (this.confirmFunction) {
                    this.loading = true;
                    this.confirmResult = await this.confirmFunction();
                }
                this.$close(this.confirmResult);
            } catch (error) {
                this.$error(error);
            }
        },
        async clickChange() {
            this.$close(await this.changeFunction());
        },
        clickNo() {
            this.$close(this.cancelResult);
        },
    },
};
</script>
<style lang="less" scoped>
.change-sms {
    color: #169ad3;
    cursor: pointer;
    text-decoration: underline;
    text-transform: uppercase;
    margin-left: 10px;
}
</style>
