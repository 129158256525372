<template>
    <div class="message-box dialog-mask">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('settings.set-primary-method.title') }}</h4>
                </div>
                <div class="modal-body">
                    <p>{{ $t('settings.set-primary-method.desc-1') }}</p>
                    <p>
                        {{ $t('settings.set-primary-method.desc-2') }} <strong>{{ primaryMethodTo }}</strong>
                    </p>
                    <p>{{ $t('settings.set-primary-method.desc-3') }}</p>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :loading="loading"
                        data-cy="buttons"
                        btn-primary-id="confirm"
                        :btn-primary-text="$t('settings.set')"
                        :btn-secondary-text="$t('cancel')"
                        :primary-button-click="clickYes"
                        :secondary-button-click="clickNo"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModalMultiButtons } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        ModalMultiButtons,
    },
    mixins: [modalEscMixin],
    props: {
        confirmResult: {
            type: Boolean,
            default: true,
        },
        confirmFunction: {
            type: Function,
            default: null,
        },
        cancelResult: {
            type: Boolean,
            default: false,
        },
        primaryMethodTo: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    created() {
        this.handleEscCallbackToMixin = this.clickNo;
    },
    methods: {
        async clickYes() {
            try {
                if (this.confirmFunction) {
                    this.loading = true;
                    this.confirmResult = await this.confirmFunction();
                }
                this.$close(this.confirmResult);
            } catch (error) {
                this.$error(error);
            }
        },
        clickNo() {
            this.$close(this.cancelResult);
        },
    },
};
</script>
