import { CODE_ESCAPE } from '@/constants/constants';

/**
 * Mixin for esc Modal
 */
export default {
    data() {
        return {
            handleEscCallbackToMixin: () => {},
        };
    },
    mounted() {
        document.addEventListener('keyup', this.handleKeyup);
    },
    destroyed() {
        document.removeEventListener('keyup', this.handleKeyup);
    },
    methods: {
        handleKeyup(e) {
            if (e.code === CODE_ESCAPE) {
                this.handleEscCallbackToMixin();
            }
        },
    },
};
