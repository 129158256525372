import axios from 'axios';
import { paramsFormatter, accountErrorHandling } from './intercepters';

const accountSettingAPI = axios.create({
    baseURL: `/api/account-setting/v1.0`,
    withCredentials: true,
});

accountSettingAPI.defaults.headers.common['x-arena-fe-version'] = `account-${process.env.VUE_APP_VERSION}`;
if (window.application && window.application.sessionId) {
    accountSettingAPI.defaults.headers.common['x-nxl-session-id'] = window.application.sessionId;
}

accountSettingAPI.interceptors.request.use(paramsFormatter);
accountSettingAPI.interceptors.response.use((response) => response.data, accountErrorHandling);

export default accountSettingAPI;
