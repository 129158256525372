import cdnAPI from './cdn';
import accountAPI from './account';
import globalAccountAPI from './global-account';
import regionalAPI from './regional';
import { countryApi, coreApi } from './core'; // core api
import selfServiceAPI from './self-service';
import accountSettingAPI from './account-setting';
import paymentAPI from './payment';

const pluginAPI = {
    install(Vue) {
        Vue.prototype.$accountAPI = accountAPI;
        Vue.prototype.$globalAccountAPI = globalAccountAPI;
        Vue.prototype.$cdnAPI = cdnAPI;
        Vue.prototype.$selfServiceAPI = selfServiceAPI;
        Vue.prototype.$accountSettingAPI = accountSettingAPI;

        Vue.prototype.$countryAPI = countryApi;
        Vue.prototype.$coreAPI = coreApi;

        Vue.prototype.$regionalAPI = regionalAPI;

        Vue.prototype.$paymentAPI = paymentAPI;
    },
};

export default pluginAPI;
