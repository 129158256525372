<template>
    <div class="message-box dialog-mask">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t(title, i18nData) }}</h4>
                </div>
                <div class="modal-body">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-html="$t(message, i18nData)"></p>
                </div>
                <div class="modal-footer">
                    <div class="col-xs-6 pull-right">
                        <FormButton
                            id="modalPrimaryButtonId"
                            data-cy="success-modal-close"
                            class="btn-black"
                            :button-click="click"
                            :label="$t(closeLabel)"
                        >
                        </FormButton>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FormButton } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        FormButton,
    },
    mixins: [modalEscMixin],
    props: {
        title: {
            type: String,
            default: 'success',
        },
        message: {
            type: String,
            default: '',
        },
        closeLabel: {
            type: String,
            default: 'close',
        },
        i18nData: {
            type: Object,
            default: () => {},
        },
        actionAfterClose: {
            type: Function,
            default: () => {},
        },
    },
    created() {
        this.handleEscCallbackToMixin = this.click;
    },
    methods: {
        click() {
            this.$close(true);
            this.actionAfterClose();
        },
    },
};
</script>
