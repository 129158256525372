<template>
    <div class="message-box dialog-mask modal-mfa-success">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('settings.mfa.success.title') }}</h4>
                </div>
                <div class="modal-body">
                    <p>{{ $t('settings.mfa.success.desc') }}</p>
                    <h5 class="heading-normal backup-code">{{ $t('settings.mfa.success.generate-backup-code') }}</h5>
                    <p>{{ $t('settings.mfa.success.generate-backup-code-desc') }}</p>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :loading="loading"
                        data-cy="buttons"
                        btn-primary-id="confirm"
                        btn-secondary-id="close"
                        :btn-primary-text="$t('settings.mfa.success.generate-backup-code')"
                        :btn-secondary-text="$t('close')"
                        :primary-button-click="clickYes"
                        :secondary-button-click="clickNo"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ModalMultiButtons } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        ModalMultiButtons,
    },
    mixins: [modalEscMixin],
    props: {
        confirmResult: {
            type: Boolean,
            default: true,
        },
        confirmFunction: {
            type: Function,
            default: null,
        },
        cancelResult: {
            type: Boolean,
            default: false,
        },
        i18nData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapGetters('settings', ['authType']),
        auth2FAEventType() {
            switch (this.authType) {
                case 'TOTP':
                    return 'App';
                case 'SMS':
                    return 'SMS';
                default:
                    return 'Email';
            }
        },
    },
    created() {
        this.handleEscCallbackToMixin = this.clickNo;
    },
    methods: {
        async clickYes() {
            dataLayer.push({
                event: '2FA Backup Codes Initiated',
                '2FA_codeGenerationSource': '2FA Activation',
                '2FAType': this.auth2FAEventType,
            });
            try {
                if (this.confirmFunction) {
                    this.loading = true;
                    this.confirmResult = await this.confirmFunction();
                }
            } catch (error) {
                this.$error(error);
            }
            this.$close(this.confirmResult);
        },
        clickNo() {
            this.$close(this.cancelResult);
        },
    },
};
</script>
<style lang="less">
.modal-mfa-success {
    .backup-code {
        margin-top: 20px !important;
    }
}
.ru,
.es {
    .modal-mfa-success {
        .submit-button-group {
            .btn-block {
                font-size: 11px;
            }
        }
    }
}
</style>
