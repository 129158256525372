<template>
    <div class="message-box dialog-mask">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t(title, i18nData) }}</h4>
                </div>
                <div class="modal-body">
                    <!-- TODO: v-html can lead to XSS attacks and should be avoided  -->
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-html="$t(content, i18nData)"></p>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :loading="loading"
                        data-cy="buttons"
                        btn-primary-id="confirm"
                        :btn-primary-text="$t(confirmLabel)"
                        :btn-secondary-text="$t(cancelLabel)"
                        :primary-button-click="clickYes"
                        :secondary-button-click="clickNo"
                        :secondary-button-hide="!cancelLabel"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModalMultiButtons } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        ModalMultiButtons,
    },
    mixins: [modalEscMixin],
    props: {
        title: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: true,
        },
        confirmLabel: {
            type: String,
            default: 'Ok',
        },
        confirmResult: {
            type: Boolean,
            default: true,
        },
        confirmFunction: {
            type: Function,
            default: null,
        },
        cancelLabel: {
            type: String,
            default: 'cancel',
        },
        cancelResult: {
            type: Boolean,
            default: false,
        },
        cancelFunction: {
            type: Function,
            default: null,
        },
        i18nData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        confirmedResult: {
            get() {
                return this.confirmResult;
            },
            set(newResult) {
                return newResult;
            },
        },
    },
    created() {
        this.handleEscCallbackToMixin = this.clickNo;
    },
    methods: {
        async clickYes() {
            try {
                if (this.confirmFunction) {
                    this.loading = true;
                    this.confirmedResult = await this.confirmFunction();
                }
                this.$close(this.confirmedResult);
            } catch (error) {
                this.$error(error);
            }
        },
        async clickNo() {
            try {
                if (this.cancelFunction) {
                    this.cancelResult = await this.cancelFunction();
                }
                this.$close(this.cancelResult);
            } catch (error) {
                this.$close(this.cancelResult);
            }
        },
    },
};
</script>
