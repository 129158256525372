<template>
    <div class="message-box dialog-mask legacy-email">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('login.legacy.email.title') }}</h4>
                </div>
                <div class="modal-body">
                    <!-- TODO: v-html can lead to XSS attacks and should be avoided  -->
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-html="$t('login.legacy.email.message')"></p>
                    <div class="new-email">
                        <span
                            >{{ $t('login.legacy.email.email') }} <span class="email">{{ email }}</span></span
                        >
                    </div>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        data-cy="buttons"
                        :loading="loading"
                        :btn-primary-text="$t('login.legacy.email.button')"
                        :btn-secondary-text="$t('cancel')"
                        :primary-button-click="closeWithEmail"
                        :secondary-button-click="closeModal"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModalMultiButtons } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    name: 'ModalLegacyEmail',
    components: {
        ModalMultiButtons,
    },
    mixins: [modalEscMixin],
    props: {
        email: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {},
    created() {
        this.handleEscCallbackToMixin = this.closeModal;
    },
    methods: {
        closeModal() {
            this.$close(false);
        },
        closeWithEmail() {
            dataLayer.push({
                event: 'SignupLoginErrorClick',
                errorName: 'Legacy Email',
                errorClick: 'Login With Email',
            });
            this.$close(true);
        },
    },
};
</script>

<style lang="less" scoped>
.legacy-email {
    .new-email {
        margin-top: 20px;
        .email {
            font-weight: bold;
        }
    }
}
</style>
