<template>
    <div
        ref="tooltip-anchor"
        tabindex="0"
        class="tooltip-anchor"
        :class="{
            open: showTooltip,
        }"
        @click="tooltipClickHandler($event)"
        @focusout="focusOut"
    >
        <span
            @click="() => (hover ? null : toggleTooltip())"
            @mouseleave="() => (hover ? hoverAction() : null)"
            @mouseenter="() => (hover ? hoverAction() : null)"
        >
            ?
        </span>
        <div class="tooltip-caret">
            <transition name="fade">
                <div v-if="showTooltip" ref="tooltip-body" class="tooltip-body" :style="{ left: leftValue + 'px' }">
                    <div class="tooltip-content">
                        <slot v-if="hasCustomMessageSlot" name="custom-message" />
                        <span v-else>{{ $t(message) }}</span>
                    </div>
                    <div class="tooltip-arrow"></div>
                    <img v-if="!hover" class="tooltip-close" :src="`${imgHost}global/bt_close5.png`" />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
const initialLeftValue = -25;

export default {
    name: 'Tooltip',
    props: {
        message: {
            type: String,
            default: '',
        },
        hover: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showTooltip: false,
            windowWidth: 0,
            leftValue: initialLeftValue,
        };
    },
    computed: {
        hasCustomMessageSlot() {
            return !!this.$slots['custom-message'];
        },
    },
    watch: {
        showTooltip(show) {
            const anchor = this.$refs['tooltip-anchor'];
            if (show && anchor) anchor.focus();
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.checkTooltipView);

            this.checkTooltipView();
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkTooltipView);
    },
    methods: {
        toggleTooltip() {
            this.showTooltip = !this.showTooltip;
            setTimeout(() => {
                this.checkTooltipView();
            });
        },
        tooltipClickHandler(event) {
            const key = this.getTooltipEventClass(event);
            if (!key) return;
            if (this.isTooltipAnchor(key) || this.isTooltipCaret(key) || this.isTooltipClose(key)) {
                this.toggleTooltip();
            }
        },
        isTooltipAnchor(key) {
            return this.isTooltipElement(key, 'anchor');
        },
        isTooltipCaret(key) {
            return this.isTooltipElement(key, 'caret');
        },
        isTooltipClose(key) {
            return this.isTooltipElement(key, 'close');
        },
        isTooltipElement(key, tag) {
            return key.indexOf(`tooltip-${tag}`) >= 0;
        },
        getTooltipEventClass(event) {
            return !event || !event.target ? '' : event.target.className;
        },
        checkTooltipView() {
            this.windowWidth = document.documentElement.clientWidth;

            const body = this.$refs['tooltip-body'];
            if (body) {
                const { right } = body.getBoundingClientRect();
                const size = this.windowWidth - (this.windowWidth <= 375 ? 45 : 65);
                if (size < right) {
                    this.leftValue -= right - size;
                } else if (this.leftValue < initialLeftValue) {
                    this.leftValue -= Math.max(right - size, initialLeftValue);
                }
            }
        },
        focusOut(e) {
            if (!e.currentTarget.contains(e.relatedTarget)) this.showTooltip = false;
        },
        hoverAction() {
            this.toggleTooltip();
        },
    },
};
</script>

<style lang="less" scoped>
.tooltip-anchor {
    background-color: #4f5159;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 14px;
    justify-content: center;
    margin-left: 10px;
    width: 14px;
    outline: none;
    &--custom-icon {
        background-color: transparent !important;
    }
    &.hover,
    &.open {
        background-color: #7b7f8c;
    }
    &:focus {
        outline: none;
    }
    > span {
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        display: grid;
        font-size: 8.5px;
        font-weight: bold;
        justify-content: center;
        width: 14px;
    }
    .tooltip-caret {
        height: 0;
        width: 0;
    }
    .tooltip-body {
        align-content: center;
        background: #121212;
        border-radius: 5px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
        cursor: default;
        display: flex;
        margin-top: 20px;
        position: relative;
        width: 291px;
        z-index: 1;
        .tooltip-content {
            color: #bfbfbf;
            font-size: 11px;
            line-height: 1.64;
            padding: 15px 20px;
        }
        .tooltip-close {
            cursor: pointer;
            height: 8px;
            opacity: 0.6;
            position: absolute;
            right: 10px;
            top: 10px;
            width: 7px;
            &:hover {
                opacity: 1;
            }
        }
    }
}
</style>
