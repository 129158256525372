<template>
    <div class="form-group" :class="{ 'has-error': hasError }">
        <div id="captcha-box" class="captcha-box g-recaptcha"></div>
        <p v-show="hasError" class="help-block">{{ overrideErrorText }}</p>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import recaptchaKeys from '@/config/recaptcha-key';

export default {
    name: 'ReCaptcha',
    props: {
        hasError: {
            type: Boolean,
            value: false,
        },
        overrideErrorText: {
            type: String,
            default: null,
            note: 'Replace error Text',
        },
    },
    mounted() {
        this.setShowCaptcha(true);
        let lang = this.$route.params.locale;
        lang = lang.replace('zh', 'zh-TW'); // zh is converted to zh-CN automatically if there is no country code specified
        const googleRecaptchaScript = document.createElement('script'),
            recaptchaURL = `https://www.google.com/recaptcha/enterprise.js?hl=${lang}&onload=rcOnloadCallback&render=explicit`;
        googleRecaptchaScript.setAttribute('src', recaptchaURL);

        window.rcOnloadCallback = () => {
            window.rcWidgetId = window.grecaptcha.enterprise.render('captcha-box', {
                sitekey: recaptchaKeys.v2Visible,
                callback: (token) => {
                    if (token) {
                        this.$emit('input', token);
                        this.$emit('captcha-completed');
                        this.setV2Token(token);
                    }
                },
                theme: 'dark',
            });
            this.setV2WidgetId(window.rcWidgetId);
        };
        document.head.appendChild(googleRecaptchaScript);
    },
    beforeDestroy() {
        window.grecaptcha.enterprise.reset(window.rcWidgetId);
        this.setShowCaptcha(false);
    },
    methods: {
        ...mapMutations('recaptcha', ['setV2WidgetId', 'setV2Token', 'setShowCaptcha']),
    },
};
</script>

<style lang="less">
.captcha-box {
    transform: scale(1.06);
    transform-origin: 0 0;
    margin-bottom: 7px;
}
@media (max-width: 740px) {
    .captcha-box {
        transform: scale(1.19);
        transform-origin: 0 0;
        margin-bottom: 15px;
    }
    .toy {
        .captcha-box {
            transform: scale(1.06);
            transform-origin: 0 0;
        }
    }
}
@media (max-width: 400px) {
    .captcha-box {
        width: 320px;
        margin: 0 auto 7px;
        transform: scale(1.06);
        transform-origin: 0 0;
    }
}
</style>
